export const FruitData = [
  {
    name: "Ananas",
    image: `${process.env.PUBLIC_URL}/images/fruits/ananas.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-ananas.jpg`,
    saison: "novembre, décembre, janvier, février",
    aPropos: "Fruit exotique, l’ananas a longtemps été considéré comme un bien rare et précieux.",
    recolte: "Une fois le fruit complètement mûr, après 12 à 15 mois, vient la période de la récolte. Si elle est trop précoce, l’ananas sera sans saveur et exempt d’arôme. Trop tardive, le fruit commencera à fermenter.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de manganèse.",
    bienChoisir: "Soupesez et sentez-le : il doit être lourd et parfumé. La couleur n’est pas un critère de choix, elle dépend surtout de l’origine géographique du fruit.",
    conservation: "6 jours à température ambiante.",
    nutriscore: "A",
    funFact: "L'ananas est longtemps resté reservée aux tables royales, dont la culture s'effectuait sous serre.",
    nomRecette: "Granité d'ananas",
    recette: [
      "300g de pulpes d'ananas frais",
      "1 citron vert", 
      "12cl de rhum ambré",
      "80g de sucre",
      "20cl d'eau"
    ],
    etapesRecette: [
      "Mixez la pulpe d’ananas et le rhum.",
      "Préparez le sirop : portez l'eau à ébullition avec le sucre. Laissez-le frémir 2 min. puis retirez du feu. Incorporez le sirop obtenu et le jus du citron au mélange rhum-ananas. Versez le tout dans un plat large (inox de préférence) puis faites prendre en glace au congélateur.",
      "Au bout de 30 min, écrasez les premiers cristaux de glace. Recommencez deux fois ou plus, jusqu'à cristallisation complète en paillettes. C'est un peu long, mais vous pouvez le préparer 2 ou 3 jours avant de le consommer.",
      "Présentez ce granité dans des verres givrés."
    ]
  },
  {
    name: "Avocat",
    image: `${process.env.PUBLIC_URL}/images/fruits/avocat.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-avocat.jpg`,
    saison: "novembre, décembre, janvier, février, mars, avril",
    aPropos: "Douceur et onctuosité de la chair de l’avocat en font un partenaire gourmand au quotidien.",
    recolte: "Une fois semé, il faut compter de 4 à 6 ans pour que l’avocatier commence à produire des fruits. Contrairement à la plupart des fruits, les avocats poussent sur l’arbre sans mûrir. Ce n’est qu’à la récolte que le processus se déclenche.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine K1 et de potassium.",
    bienChoisir: "Pour l’avocat, ce n’est pas le critère de maturité qui guidera votre choix, puisqu’il continue à mûrir une fois cueilli cependant, assurez-vous que la chair est souple à proximité du pédoncule.",
    conservation: "4 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Originaire de Chine, l’avocatier poussait à l’état sauvage il y a 5 000 ans. Il fut introduit en Occident par Alexandre le Grand qui emprunta la célèbre Route de la Soie.",
    nomRecette: "Guacamole",
    recette: [
      "2 avocats mûrs",
      "1/2 tomate", 
      "1 poivron",
      "1 citron vert",
      "1 oignon rouge",
      "coriandre en branche",
      "1 pincée de cumin",
      "tabasco",
      "2 c. à soupe d'huile d'olive",
      "sel & poivre"
    ],
    etapesRecette: [
      "Coupez vos avocats en deux, retirez les noyaux et récupérez la pulpe à l'aide d'une cuillère. Placez ensuite cette pulpe dans le mixeur.",
      "Ajoutez le jus de citron, vert de préférence, dans le mixeur avec la pulpe d'avocats. Celui-ci permettra à l'avocat de conserver sa couleur verte sans noircir.",
      "Ajoutez également à cette préparation la demi-tomate et mixez le tout. À cette étape de la recette du guacamole, vous pouvez utiliser une simple fourchette pour écraser le tout.",
      "Épluchez l'oignon et hachez-le finement puis coupez finement le poivron. Incorporez-les à la préparation avec la coriandre les épices (cumin, tabasco), l'huile, le sel et le poivre.",
      "Mixez le tout jusqu'à l'obtention d'une belle purée onctueuse que vous pourrez réserver au frais avant de la servir en l'ayant préalablement placée dans un bol. Servez-le très frais à l'apéritif",
      "Traditionnellement, le guacamole mexicain se mange avec des chips mexicaines, mais vous pouvez également le présenter sur des toasts, en verrines, avec des gressins, des croutons à l'ail ou des légumes crus"
    ]
  },
  {
    name: "Banane",
    image: `${process.env.PUBLIC_URL}/images/fruits/banane.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-banane.jpg`,
    saison: "janvier, février, mars, avril, mai, juin, juillet, aout, septembre, octobre, novembre, décembre",
    aPropos: "D’un point de vue culinaire, on distingue 2 types de bananes : les bananes à dessert, comme celles que nous mangeons au petit-déjeuner, et les bananes à cuire, comme le plantain.",
    recolte: "La récolte des bananes se fait d'une coupe nette du rameau porteur des fruits, idéalement à la machette. La récolte a lieu 8 à 10 mois après la mise en terre. Cela peut varier en fonction du climat et de la variété de bananier cultivée.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B6, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "Plus la banane présente de marques vertes, moins elle est mûre et plus elle se conservera longtemps. On peut alors l’utiliser pour la cuisson. Par contre, il faudra la laisser mûrir avant de la consommer crue, car à ce stade, elle est indigeste.",
    conservation: "3 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Une étude prospective, effectuée auprès de 61 000 femmes suisses, a démontré un lien entre une consommation élevée de fruits et le risque moindre de souffrir d’un cancer du rein. De tous les fruits à l’étude, c’est pour la banane que les chercheurs ont constaté la plus forte relation. La banane aurait le même effet bénéfique sur le risque de cancer colorectal, autant chez les femmes que les hommes.",
    nomRecette: "Bananes flambées",
    recette: [
      "2 bananes bien mûres",
      "1 sachet de sucre vanillé", 
      "1 petit verre de rhum",
      "2 noisettes de beurre",
    ],
    etapesRecette: [
      "Faites chauffer une poêle, faites-y fondre le beurre et ajoutez-y les bananes coupées en long, faites dorer de chaque côté.",
      "Saupoudrez de sucre vanillé, versez le rhum et faites flamber : lorsqu'il n'y aura plus de flammes, servez !",
    ]
  },
  {
    name: "Cerise",
    image: `${process.env.PUBLIC_URL}/images/fruits/cerise.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-cerise.jpg`,
    saison: "mai, juin, juillet",
    aPropos: "La cerise est le premier fruit à noyau de l’année. Sucrée ou acidulée, elle peut se consommer au choix crue ou cuite !",
    recolte: "Les cerises sont récoltées à la main, au fur et à mesure de leur maturité. L’opération est longue et délicate puisqu'on estime le coût de récolte de 25 à 33 % du prix de vente global.",
    bienfaits: "Un peu plus calorique que les autres fruits, elle possède néanmoins des qualités nutritionnelles. Ce fruit est composé d'eau, de glucides, de fibres, de potassium et de polyphénols.",
    bienChoisir: "La cerise doit présenter un pédoncule vert et solidement attaché. Elle doit être brillante et sans traces de meurtrissures !",
    conservation: "4 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Les Romains prétendent l’avoir introduit en Europe. D’après leurs dires, ce serait le général Lucullus qui l’aurait rapporté d’Asie mineure en l’an 73. Des textes plus anciens démontrent toutefois que le fruit rouge était déjà connu en Grèce, en Italie et en Gaule, bien avant cette date.",
    nomRecette: "Sorbet à la cerise & cerises poêlées au basilic",
    recette: [
      "750g de cerises",
      "15cl d'eau", 
      "115g de fructose cristallisé",
    ],
    etapesRecette: [
      "Lavez et séchez les cerises. Équeutez-les et dénoyautez-les.",
      "Mettez les cerises dans le bol d’un robot mixeur.",
      "Faites chauffer l’eau et le fructose. Quand le liquide arrive à ébullition, versez-le sur les cerises et mixez très finement.",
      "Laissez refroidir puis faites prendre en sorbetière. Entreposez au congélateur."
    ]
  },
  {
    name: "Citron",
    image: `${process.env.PUBLIC_URL}/images/fruits/citron.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-citron.jpg`,
    saison: "novembre, décembre, janvier, février",
    aPropos: "Le citron occupe une place de choix dans nos cuisines. On l'aime pour sa chair juteuse, sa saveur qui oscille entre acide et amère, et sa belle couleur jaune doré.",
    recolte: "Les citrons sont récoltés en fonction de leur calibre, et sont ensuite mis en conservation. De tous les agrumes, ce sont ceux dont le temps de conservation est le plus élevé.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9 et de vitamine C.",
    bienChoisir: "Le citron doit être lourd et ferme. Son écorce brillante, d’un jaune éclatant.",
    conservation: "7 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Le citronnier fleurit plusieurs fois dans l’année : on dit qu’il est remontant. Un même citronnier peut donc fournir des fruits en toute saison.",
    nomRecette: "Cake au citron",
    recette: [
      "1 citron",
      "200g de farine", 
      "1 sachet de levure chimique",
      "175g de sucre semoule",
      "80 g de beurre demi-sel ramolli",
      "3 oeufs",
      "1Ocl de lait"
    ],
    etapesRecette: [
      "Préchauffez votre four th.6 (180°C).",
      "Lavez très soigneusement le citron (surtout s'il n'est pas bio) puis essuyez-le. Râpez le zeste dans un bol et pressez le jus à part.",
      "Fouettez le beurre avec le sucre jusqu'à obtenir une pommade bien homogène. Ajoutez les œufs et mélangez, puis ajoutez le lait, le jus de citron et les zestes et incorporez la farine tamisée et la levure.",
      "Versez cette préparation dans un moule à cake beurré et fariné et enfournez pour 35 à 45 min (pour vérifier la cuisson de votre cake, plantez la pointe d'un couteau au centre, si la pointe ressort intacte, c'est qu'il est cuit !).",
      "Au bout d'1/4 d'heure de cuisson, faites une entaille sur le dos du cake pour qu'il cuise et gonfle de façon plus esthétique",
      "Laissez refroidir avant de le démouler"
    ]
  },
  {
    name: "Citron vert",
    image: `${process.env.PUBLIC_URL}/images/fruits/citronvert.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-citronvert.jpg`,
    saison: "avril, mai, juin, juillet, aout, septembre, octobre, novembre",
    aPropos: "Plein de caractère, le citron vert est un fruit tropical. Festif et convivial, il possède un arôme caractéristique qui dynamise vos plats et cocktails.",
    recolte: "Les citrons sont récoltés en fonction de leur calibre, et sont ensuite mis en conservation. De tous les agrumes, ce sont ceux dont le temps de conservation est le plus élevé.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9 et de vitamine C.",
    bienChoisir: "Le citron doit être lourd et ferme. Son écorce brillante, d’un vert éclatant.",
    conservation: "7 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Contrairement aux idées reçues, le citron vert n'est pas un citron jaune qui n'aurait pas assez mûri. Au contraire, il s'agit d'une tout autre variété, issue d'un arbuste bien différent.",
    nomRecette: "Chantilly au citron vert",
    recette: [
      "1 citron vert",
      "20 cl de crème liquide entière", 
      "1 pincée de sel",
    ],
    etapesRecette: [
      "Pressez et récupérez le jus du citron vert.",
      "Montez la crème en chantilly bien ferme à l'aide d'un batteur électrique.",
      "Ajoutez le sel et le jus de citron en continuant de fouetter.",
      "Réservez la chantilly au frais jusqu'à utilisation."
    ]
  },
  {
    name: "Figue",
    image: `${process.env.PUBLIC_URL}/images/fruits/figue.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-figue.jpg`,
    saison: "juillet, aout, septembre, octobre",
    aPropos: "La figue est un festival de couleurs et de saveurs ! Blanche, noire, rouge, violette ou bicolore, elle reste avant tout un fruit gourmand et gorgé de soleil.",
    recolte: "Seuls les figuiers femelles produisent des figues comestibles. Les figues sont récoltés à la main à partir de juillet ! ",
    bienfaits: "Grâce à sa modeste teneur en sucre et à la qualité de ses apports nutritionnels, la figue participe à un régime alimentaire diversifié. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de cuivre et de potassium.",
    bienChoisir: "Choisissez de belles figues, à la fois fermes et charnues. La figue que vous achetez doit être parfumée et à point.",
    conservation: "4 jours à température ambiante.",
    nutriscore: "A",
    funFact: "La reproduction du figuier est plus qu’originale. Dans la nature, il ne peut être pollinisé que par une minuscule guêpe, le blastophage. De son côté, l’insecte ne peut se reproduire que grâce à la fructification du figuier. Autrement dit, aucun des deux n’existerait sans l’autre !",
    nomRecette: "Figues bourguignones",
    recette: [
      "1 kg de figue fraîches",
      "sucre en poudre", 
      "1 bouteille de vin rouge",
    ],
    etapesRecette: [
      "Pelez les figues et coupez-les en deux, horizontalement. Déposez-les dans un plat en forme de coupe.",
      "Saupoudrez de sucre et noyez de vin.",
      "Laissez refroidir au réfrigérateur pendant au moins 4 heures et servez très froid.",
    ]
  },
  {
    name: "Fraise",
    image: `${process.env.PUBLIC_URL}/images/fruits/fraise.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-fraise.jpg`,
    saison: "avril, mai, juin, juillet",
    aPropos: "Chaque année au printemps, les fraises font leur retour sur les étals. Elles redonnent des couleurs à nos assiettes et de la fraicheur à nos plats !",
    recolte: "La fraise française est récoltée à la main dès les premiers jours du printemps. Les fraisiers, qui peuvent avoir plusieurs cycles de fructification.",
    bienfaits: "Elles apportent une bonne dose de vitamines et d’antioxydants. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9 et de vitamine C.",
    bienChoisir: "Les fruits doivent être brillants, de couleur uniforme, avec une collerette et un pédoncule bien verts.",
    conservation: "2 jours au réfrigérateur.",
    nutriscore: "A",
    funFact: "Dès la plus haute antiquité, elle pousse à l’état sauvage en Amérique, en Asie ainsi qu’en Europe occidentale. Les Romains en font des masques de beauté.",
    nomRecette: "Tarte aux fraises",
    recette: [
      "500 g de fraises",
      "6 c. à soupe de coulis de fraises",
      "1 pâte sablée",
      "25cl de lait",
      "1 oeuf",
      "30g de farine de blé",
      "40g de sucre en poudre",
      "1 gousse de vanille"
    ],
    etapesRecette: [
      "Préchauffez le four à 180°C (th. 6).",
      "Confectionnez la crème pâtissière. Dans une casserole, faites bouillir le lait avec la gousse de vanille préalablement coupée en deux et grattée à la pointe du couteau. Pendant ce temps, fouettez l’œuf avec le sucre en poudre jusqu’à ce que le mélange forme un ruban. Incorporez la farine de blé tamisée et continuez de mélanger en veillant bien à ne pas former de grumeaux.",
      "Après avoir ôté la gousse de vanille, versez le lait bouillant sur la préparation en tournant bien avec une cuillère en bois. Replacez la casserole sur le feu et faites cuire en remuant constamment. Stoppez la cuisson après ébullition.",
      "Versez la crème pâtissière sur le fond de tarte. Lavez et équeutez les fraises puis disposez-les joliment sur la crème pâtissière."
    ]
  },
  {
    name: "Framboise",
    image: `${process.env.PUBLIC_URL}/images/fruits/framboise.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-framboise.jpg`,
    saison: "mai, juin, juillet, aout, septembre",
    aPropos: "Sensuelle et charnue, la framboise possède une saveur parfumée et suave. Elle se déguste aussi bien nature ou cuisinée.",
    recolte: "La framboise se récolte en été mais, grâce aux variétés remontantes, vous la trouverez sur les étals jusqu’aux premières gelées de l’automne. La récolte de ce fruit fragile et délicat exige beaucoup de main d’oeuvre.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de manganèse.",
    bienChoisir: "Préférez des framboises bien charnues, pourvues de grains intacts et veloutés. La framboise est fragile et délicate.",
    conservation: "1 jour au réfrigérateur.",
    nutriscore: "A",
    funFact: "En France, on ne croque cette savoureuse baie qu’au 19ème siècle. Elle était jusque-là surtout cultivée pour son parfum et ses vertus médicinales, ainsi que pour la fabrication de boissons",
    nomRecette: "Financiers aux framboises",
    recette: [
      "30 framboises",
      "200g de sucre en poudre", 
      "150g de poudre d'amande",
      "125 g de beurre",
      "75 g de farine",
      "1 sachet de sucre vanillé",
      "4 blancs d'oeufs",
      "1 c. à café de basilic"
    ],
    etapesRecette: [
      "Préchauffez votre four à 200°C (th.6/7).",
      "Mélangez la poudre d'amande, le sucre et le sucre vanillé. Incorporez un à un les blancs d'œufs sans les fouetter. Fouettez la pâte puis ajoutez la farine.",
      "Dans une casserole, faites fondre le beurre jusqu'à ce qu'il commence à brunir puis laissez-le refroidir. Ajoutez le beurre refroidi et le basilic à la pâte et mélangez. Ajoutez les framboises et remuez délicatement.",
      "Garnissez les empreintes du moule à financiers en répartissant les framboises. Enfournez 20 min et démoulez à chaud."
    ]
  },
  {
    name: "Fruit du dragon",
    image: `${process.env.PUBLIC_URL}/images/fruits/fruit-dragon.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-dragon.jpg`,
    saison: "janvier, février, mars, avril, mai, juin, juillet, aout, septembre, octobre, novembre, décembre",
    aPropos: "Fruit exotique né du cactus, elle s’apprécie toute l’année, apportant aux étals sa couleur éclatante. Le fruit du dragon, ou pitaya, se déguste crue : il est très rafraîchissant et sa saveur est fine, douce et parfumée.",
    recolte: "Le fruit du dragon est cueilli à la main une fois que la peau a viré du vert au rouge ou au jaune. Les petites « écailles » sur la surface commenceront à brunir ou à jaunir à mesure que le fruit murira.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "Il doit être assez ferme et d'une couleur vive. Il est préférable de ne pas choisir un fruit présentant des taches ou des meurtrissures.",
    conservation: "Quelques heures après la coupe.",
    nutriscore: "A",
    funFact: "Les colons français décident d’en importer quelques plants au Vietnam. Le « fruit du dragon » y est rapidement cultivé, mais réservé, dans les premiers temps, exclusivement à la famille royale et à quelques familles privilégiées.",
    nomRecette: "Sorbet express à la pitaya",
    recette: [
      "1 fruit du dragon coupé en morceaux et congelé",
      "2 bélimbis coupés en morceaux et congelés ou à défaut un citron vert (le jus)", 
      "1 cuillère à soupe de sucre complet ou de canne",
      "50 ml de jus d'orange frais"
    ],
    etapesRecette: [
      "Sortir les fruits congelés du congélateur et les laisser à l’air libre pendant 5 minutes afin qu’ils ramollissent légèrement.",
      "Puis les disposer dans un blender avec le sucre et le jus d’orange. Mixer jusqu’à obtenir une texture onctueuse.",
      "Servir aussitôt ou réserver dans une boîte hermétique au congélateur.",
    ]
  },
  {
    name: "Grenade",
    image: `${process.env.PUBLIC_URL}/images/fruits/grenade.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-grenade.jpg`,
    saison: "octobre, novembre, décembre, janvier, février, mars",
    aPropos: "La grenade est l'un des plus anciens fruits connus. Elle est récoltée depuis des millénaires autour du bassin méditerranéen. Aliment plaisir grâce à son parfum subtil, la grenade donne un agréable petit brin de fantaisie et d’exotisme.",
    recolte: "Les fruits du grenadier se récoltent à partir du mois de septembre et jusqu’en décembre.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine C, de cuivre et de potassium.",
    bienChoisir: "L'épiderme doit être rouge, bien lisse, brillant et exempt de taches brunes. Plus le fruit est lourd et plus la pulpe sera justeuse !",
    conservation: "10 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Mentionnée dans la Bible et dans le Coran, la grenade est depuis des temps immémoriaux symbole de vie et de fertilité, probablement en raison de ses très nombreuses graines.",
    nomRecette: "Eau détoxifiante à la grenade",
    recette: [
      "1 grenade",
      "1/2 citron jaune", 
      "1L d’eau minérale",
      "15 feuilles de menthe fraîche"
    ],
    etapesRecette: [
      "Prélevez les graines de grenade et ôtez la membrane blanche. Placez les graines dans une carafe ou dans des Mason Jar. Lavez et coupez le demi-citron en rondelles. Ajoutez-les dans le récipient.",
      "Lavez les feuilles de menthe fraîche et disposez-les dans la carafe. Versez l’eau minérale.",
      "Placez la carafe au minimum 2 heures au réfrigérateur afin de bien faire infuser la grenade et la menthe. Servez cette eau detox bien fraîche, en ajoutant éventuellement quelques glaçons.",
    ]
  },
  {
    name: "Jackfruit",
    image: `${process.env.PUBLIC_URL}/images/fruits/jackfruit.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-jackfruit.jpg`,
    saison: "octobre, novembre, décembre, janvier",
    aPropos: "Originaire d'Asie du Sud, le jackfruit et sa chair fibreuse, peut se substituer à la viande dans de nombreux plats. Sa chair de couleur jaune-orangée contient une saveur douce et délivre une odeur fortement sucrée comme celle de l'ananas ou de la mangue.",
    recolte: "Les fruits sont récoltés à l'aide d'ébrancheurs ou d'une faucille de septembre à décembre.",
    bienfaits: "Sa richesse en sucres lents et en fibres participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B2 et de vitamine B9.",
    bienChoisir: "Préférez les fruits pas trop mûrs à la pulpe encore ferme, sinon le goût et l'odeur seront assez forts. Lorsque le fruit est mûr, il fait un bruit sourd et creux lorsqu'on le tapote.",
    conservation: "4 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Ses fruits ne poussent pas sur des branches mais sur le tronc. Ses noyaux sont indigestes crus mais ils peuvent être cuits et consommés comme des marrons !",
    nomRecette: "Curry de Jaskfruit",
    recette: [
      "300g de jackfruit (fruit du jacquier)",
      "100 g de lentilles corail", 
      "200 g de riz cuit",
      "1 gousse d’ail",
      "1 oignon",
      "1 c. à soupe de pâte à curry panang",
      "20 cl de crème soja",
      "300 ml d’eau",
      "200 g de coulis de tomate",
      "2 c. à soupe de noix de cajou",
      "1 c. à soupe de sauce tamari",
      "1 c. à soupe d’huile d’olive",
      "coriandre fraîche",
      "sel & poivre"
    ],
    etapesRecette: [
      "Éplucher et émincer l’ail et l’oignon.",
      "Dans une marmite, faire revenir dans un peu d’huile l’ail et l’oignon ainsi que le fruit du jacquier pendant 5 min.",
      "Ajouter la pâte de curry, l’eau, le coulis de tomate et les lentilles corail.",
      "Cuire à couvert pendant 40 min à feu moyen.",
      "Une fois cuit, ajouter la crème soja et mélanger.",
      "Verser dans des bols avec du riz cuit et ajouter les noix de cajou et la coriandre"
    ]
  },
  {
    name: "Kiwi",
    image: `${process.env.PUBLIC_URL}/images/fruits/kiwi.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-kiwi.jpg`,
    saison: "novembre, décembre, janvier, février, mars",
    aPropos: "Dégusté depuis 2 000 ans en Chine, le kiwi séduit aujourd’hui le monde entier. En France, il est cultivé dans trois régions, dont l’une détient même un Label Rouge !",
    recolte: "Le kiwi mûrit après la récolte. On dit de lui que c’est un fruit climactérique. En France, sa récolte s'effectue en automne, juste avant les premières gelées.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine C, de vitamine K1 et de cuivre.",
    bienChoisir: "Préférez le kiwi mûr, qui est souple au toucher, juteux et sucré.Cependant, comme la poire ou l’avocat, vous pouvez ainsi l’acheter légèrement immature.",
    conservation: "7 jours dans le réfrigérateur.",
    nutriscore: "A",
    funFact: "Le kiwi est le fruit d’une liane qui ressemble un peu à la vigne. Il est originaire de Chine, où il est dégusté depuis plus de 2 000 ans.",
    nomRecette: "Esquimaux au kiwi",
    recette: [
      "4 kiwis",
      "2 c. à soupe de sucre", 
      "2 c. à soupe d'eau",
    ],
    etapesRecette: [
      "Coupez trois kiwis en deux, ôtez le maximum de graines. Creusez ensuite le centre et écrasez la pulpe avec le dos d'une cuillère.",
      "Passez la chair au mixeur avec l'eau et le sucre.",
      "Coupez le dernier kiwi en tranches après l'avoir épluché.",
      "Dans vos moules à esquimaux, placez quelques rondelles de kiwis puis versez le mélange mixé.",
      "Placez au congélateur pour 4 heures environ"
    ]
  },
  {
    name: "Litchi",
    image: `${process.env.PUBLIC_URL}/images/fruits/lytchee.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-litchi.jpg`,
    saison: "novembre, décembre, janvier",
    aPropos: "Le litchi est un délicieux petit fruit rafraichissant et exotique, qui mêle les saveurs de la rose et du muscat.",
    recolte: "Lors de la récolte, les grappes de fruits sont cassées ou coupées, et les fruits, protégés du soleil, sont ensuite détachés de leur grappe. Au printemps et en été, les litchis frais proviennent de Thaïlande et d’Israël. De la mi-novembre à la mi-janvier, la plupart des fruits viennent d’Australie, de l’Île Maurice et d’Afrique du Sud, mais surtout de Madagascar.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine C, de cuivre et de polyphénols.",
    bienChoisir: "Au toucher, le fruit doit être suffisamment dur et son écorce intacte. Une fois à maturité, le litchi présente une peau rose.",
    conservation: "2 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Dans son pays d’origine et dans tout l’hémisphère nord, la saison des litchis correspond aux mois de juin et juillet ; alors que dans les pays de l’hémisphère sud, les récoltes se tiennent en décembre et janvier. Le fruit se trouve ainsi fortement associé aux fêtes de Noël et du Nouvel An.",
    nomRecette: "Macarons à la rose et aux litchis",
    recette: [
      "70 g de jus de litchi",
      "200 g de sucre glace",
      "110 g de poudre d'amande", 
      "35 g de sucre en poudre",
      "3 blancs d’oeufs",
      "100 g de chocolat blanc",
      "1/2 feuille de gélatine",
      "1 cuillère à soupe de sirop de rose"
    ],
    etapesRecette: [
      "Mixer et tamiser le sucre glace avec la poudre d'amandes. Monter les blancs d'oeufs en ajoutant le sucre en poudre au fur et à mesure, mélanger délicatement à l'aide d'une spatule en plastique. Ajouter quelques gouttes de colorant rouge pour obtenir une couleur assez prononcée.",
      "Incorporer le sucre glace et la poudre d'amandes aux blancs toujours avec une spatule jusqu'à obtenir d'un mélange souple et brillant.",
      "Dresser les macarons à l'aide d'une poche à douille lisse en leur donnant une forme de coeur, en réalisant deux gouttes ainsi vous formerez votre coeur, laisser croûter 20 minutes minimum et les faire cuire sur 3 plaques à pâtisserie identiques au four pendant 12 minutes à 150°c. Laisser refroidir.",
      "Mettre la demi feuille de gélatine dans l'eau froide. Faire fondre le chocolat puis ajouter le sirop de rose, la crème liquide et le jus de litchi.",
      "Ajouter la gélatine ramollie et essorée au mélange, remplir un bol de glaçons et surmonter d'un autre dans lequel déposer la préparation.",
      "Monter la crème, elle doit doubler de volume. Et enfin mixer à l'aide d'un mixer plongeant la préparation pour la rendre bien homogène.",
      "Monter les macarons en déposant un petite cuillère à café de crème sur une coque et refermer avec une autre.",
      "Laisser reposer au frais pendant 1 heure environ."
    ]
  },
  {
    name: "Mangoustan",
    image: `${process.env.PUBLIC_URL}/images/fruits/mangosteen.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-mangoustan.jpg`,
    saison: "juillet, aout, septembre, octobre",
    aPropos: "Derrière sa coque épaisse et violacée, le mangoustan cache une chair fondante d’un goût subtil et d'une blancheur nacrée.",
    recolte: "La culture du mangoustanier est exigeante. Après dix ans, l’arbre donne 2 ou 3 récoltes par an, en été après la saison sèche, pendant une cinquantaine d’années. Le rendement moyen d’un arbre, qui ne commence à donner qu’au bout de dix ans, est de 500 fruits sur toute sa durée de vie.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine C, de magnésium et de potassium.",
    bienChoisir: "Choisissez les mangoustans qui présentent le plus grand nombre de lobes au sommet du fruit : ce sont eux qui contiennent le plus de quartiers et renferment le moins de graines.",
    conservation: "3 jours à température ambiante.",
    nutriscore: "A",
    funFact: "L’écorce de mangoustan était autrefois découpée et séchée, puis réduite en poudre et administrée sous la forme d’une préparation à base de plantes. On pouvait aussi la laisser dans l’eau toute une nuit puis la boire en infusion, ou encore en faire une pommade à appliquer sur la peau comme une lotion.",
    nomRecette: "Salade de fruit exotique",
    recette: [
      "4 mangoustan",
      "1 banane",
      "1/2 papaye",
      "1/2 ananas",
      "2 kiwis",
      "le jus d'un demi citron",
      "sucre vanillé (optionnel)"
    ],
    etapesRecette: [
      "Epluchez tous les fruits et coupez les en morceaux en récupérant leur jus dans un récipient.",
      "Mettez les fruits et leur jus dans un plat puis saupoudrez le tout de sucre vanillé, versez le jus de citron",
      "Laisser reposer au réfrigérateur.",
      "Servir frais."
    ]
  },
  {
    name: "Mangue",
    image: `${process.env.PUBLIC_URL}/images/fruits/mangue.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-mangue.jpg`,
    saison: "novembre, décembre, janvier, février, mars, avril, mai",
    aPropos: "Sa chair, d’un beau jaune-orangé, a un délicat goût sucré. Avec plusieurs milliers de variétés, la mangue vous offre une diversité peu commune de couleurs et de saveurs",
    recolte: "En hiver et au printemps, les fruits consommés en France proviennent d’Afrique. Le reste du temps, ils viennent principalement d’Amérique du Sud, d’Israël, ainsi que d’Asie.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine B9 et de vitamine C.",
    bienChoisir: "Une mangue bien mûre doit être souple au toucher. Il est important de savoir que sa maturité se termine à l'air libre.",
    conservation: "1 jour à température ambiante.",
    nutriscore: "A",
    funFact: "La mangue est originaire des forêts de l’Inde, pays où le manguier pousse encore à l’état sauvage. Cet arbre au feuillage persistant et à la fructification généreuse est vénéré par les Hindous. Et c’est son ombrage que le Bouddha choisit pour méditer des journées entières sur la destinée de l’Homme.",
    nomRecette: "Coulis à la mangue",
    recette: [
      "2 mangues",
      "1/4 de jus de citron",
      "1 c. à soupe de sucre en poudre",
      "1 c. à soupe d'eau",
    ],
    etapesRecette: [
      "Épluchez les mangues, récupérez la chair et placez-la dans un saladier.",
      "Ajoutez le jus de citron et, à l'aide d'un mixeur plongeant, mixez.",
      "Ajoutez le sucre et l'eau, mélangez de nouveau.",
      "Servez aussitôt ou placez-le au frais, recouvert d'un film transparent"
    ]
  },
  {
    name: "Melon",
    image: `${process.env.PUBLIC_URL}/images/fruits/melon.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-melon.jpg`,
    saison: "juin, juillet, aout, septembre",
    aPropos: "Rond et savoureux, il fait partie des fruits et légumes d’été préférés des Français. Il se consomme aujourd’hui surtout comme un fruit, mais agrémente également des préparations salées.",
    recolte: "La récolte a lieu selon les différentes variétés et types de cultures. Elle se fait à la main, tôt le matin, aux heures les plus fraîches de la journée pour ne pas l’endommager.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine B9 et de potassium.",
    bienChoisir: "Le melon doit être lourd en main, son écorce doit être souple. Si le fruit est maturé, son pédoncule se décolle très facilement. Plus il est mûr et plus il sera parfumé !",
    conservation: "6 jours dans le réfrigérateur.",
    nutriscore: "A",
    funFact: "Le melon serait originaire d’Afrique. Les Egyptiens le cultivent déjà 500 ans avant notre ère. Il gagne la Grèce puis Rome vers le 1er siècle, où il est alors consommé comme légume. Il faut dire qu’à l’époque il est petit, peu sucré et on le dégustait poivré et vinaigré.",
    nomRecette: "Compotée de melon",
    recette: [
      "1 melon et demi",
      "500 g d'orange", 
      "4 c. à soupe d'édulcorant spécial cuisson",
      "2 bâtons de cannelle",
      "2 gousses de vanille",
      "2 tiges de menthe"
    ],
    etapesRecette: [
      "Lavez, séchez et effeuillez la menthe.",
      "Lavez l'orange, prélevez-en le zeste et émincez-le finement. Pressez le jus de l'orange.",
      "Dans une sauteuse, versez 20 cl d'eau et le jus d'orange. Ajoutez l'édulcorant et mélangez jusqu'à sa dissolution. Ajoutez le zeste d'orange, le bâton de cannelle et les gousses de vanille fendues en deux dans la longueur.",
      "Portez le tout à ébullition, baissez le feu et laissez cuire à petits frémissements pendant 5 min.",
      "Pendant ce temps, épépinez et épluchez le melon puis coupez-le en morceaux. Ajoutez-les ainsi que la menthe dans la sauteuse et poursuivez la cuisson 15 min en mélangeant régulièrement.",
      "Mettez le melon et le sirop dans un saladier et laissez-les refroidir complètement avant de servir. Répartissez-les dans des coupelles individuelles. Servez."
    ]
  },
  {
    name: "Myrtille",
    image: `${process.env.PUBLIC_URL}/images/fruits/baies.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-myrtille.jpg`,
    saison: "juin, juillet, aout, septembre",
    aPropos: "Petite baie gourmande, la myrtille vous séduira par sa jolie teinte bleu-noir et son goût parfumé, légèrement sucré et acidulé.",
    recolte: "La myrtille pousse sur de très petits arbrisseaux, de 50 cm de haut environ, dans les sous-bois clairs au sol acide. Les premiers fruits sont cueillis dès les premiers jours de mai, et la récolte se termine en octobre. La cueillette commence au petit jour, afin de profiter de la fraîcheur du matin.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine C, de vitamine K1 et de manganèse.",
    bienChoisir: "Fragile, la myrtille doit être choisie intacte et sans meurtrissures sur la peau. La maturité des myrtilles se repère à leur arôme et à la coloration bleu soutenu côté pédoncule.",
    conservation: "7 jours au réfrigérateur.",
    nutriscore: "A",
    funFact: "En France, la myrtille sauvage est commercialisée uniquement sur des circuits courts afin de garantir sa fraîcheur. Ce qui n’empêche en rien le vrai succès qu’elle rencontre. Elle est récoltée en Ardèche, en Lozère, dans les Vosges et les Alpes, mais aussi en Bretagne.",
    nomRecette: "Compote de myrtilles",
    recette: [
      "200g de myrtilles",
      "3 pommes",
    ],
    etapesRecette: [
      "Épluchez les pommes, évidez-les puis coupez-les en petits dés.",
      "Placez les pommes et les myrtilles dans une casserole et ajoutez 1 c. à soupe d'eau.",
      "Couvrez la casserole et laissez compoter pendant 10 min.",
      "A l'aide d'une fourchette, écrasez les fruits puis servez"
    ]
  },
  {
    name: "Noix de coco",
    image: `${process.env.PUBLIC_URL}/images/fruits/coco.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-coco.jpg`,
    saison: "novembre, décembre, janvier, février",
    aPropos: "La noix de coco s’épanouit sous la chaleur des tropiques. Ce fruit exotique évoque les îles lointaines et les paysages de rêve.",
    recolte: "La récolte s’étend sur toute l’année lorsque les fruits ont atteint leur maturité, au bout de 9 à 12 mois. Les fruits se conservent alors très longtemps, certains jusqu’à 100 semaines !",
    bienfaits: "Sa richesse en vitamines et minéraux en fait un aliment bien-être à consommer de temps à autre. Ce fruit est composé d'eau, de cuivre, de fibres, de manganèse et de potassium.",
    bienChoisir: "Secouez la noix de coco près de votre oreille. Vous devez entendre le bruit de l’eau de coco clapoter à l’intérieur. Plus il y en a, plus le fruit sera frais et sa chair goûteuse. Les yeux (3 petites taches sombres présentes à sa base) doivent être fermes, intacts et exempts de taches de moisissure",
    conservation: "7 jours à température ambiante.",
    nutriscore: "C",
    funFact: "Les noix de coco tuent plus d'hommes par an que les requins. En effet, chaque année les chutes de noix de coco tuent en moyenne 150 personnes dans le monde, soit 15 fois plus que le nombre de décès imputables aux requins !",
    nomRecette: "Brownies à la noix de coco",
    recette: [
      "125 g de beurre",
      "75 g de farine", 
      "75 g de noix de coco râpée",
      "50 g de chocolat",
      "8 dattes Medjool",
      "3 oeufs"
    ],
    etapesRecette: [
      "Pour réaliser cette recette de brownies, commencez par mixer les dattes dans un blender jusqu'à obtenir une sorte de pâte. Réservez. Puis préchauffez le four à 180°C.",
      "Faites cuire au bain-marie le chocolat avec le beurre. Hors du feu, incorporez les oeufs entiers battus préalablement en omelette puis la purée de dattes. Mélangez bien. Versez en pluie la farine et la noix de coco râpée et mélangez.",
      "Beurrez un moule rectangulaire et versez la préparation en lissant bien la surface. Saupoudrez de noix de coco râpée. Enfournez pendant 30 minutes. Démoulez à la sortie du four et découpez en parts égales. Dégustez tiède ou froid votre gâteau coco sans sucre.",
    ]
  },
  {
    name: "Olive",
    image: `${process.env.PUBLIC_URL}/images/fruits/olive.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-olive.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier",
    aPropos: "",
    recolte: "",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine K1 et de potassium.",
    bienChoisir: "",
    conservation: "4 jours à température ambiante.",
    nutriscore: "A",
    funFact: "",
    nomRecette: "Tapenade",
    recette: [
      "180 ml d’olives noires dénoyautées",
      "3 c. à soupe de câpres égouttées", 
      "80 ml de filets d’anchois égouttés",
      "2 gousses d’ail",
      "125 ml d’huile d’olive",
      "15 ml (1 c. à soupe) de jus de citron",
      "sel & poivre"
    ],
    etapesRecette: [
      "Coupez vos olives, vos câpres et filets d’anchois. Épluchez l’ail en pensant bien à extraire le germe, partie indigeste de l’ail.",
      "Incorporez l’ail au mélange d’olives, de câpres et d’anchois. Mélangez le tout et mixez-le en purée à haute vitesse.",
      "Lorsque tous vos éléments sont bien broyés, vous pouvez commencer à monter l’ensemble comme une mayonnaise en y incorporant un filet d’huile d’olive ; vous devez obtenir une texture de tapenade lisse et épaisse.",
      "Ajoutez le jus de citron puis salez peu et poivrez. Le jus de citron apportera de la fraîcheur et le poivre donnera encore plus de caractère à votre tapenade aux olives noires.",
      "Réservez-la au réfrigérateur jusqu’au moment de déguster. Servez-la bien fraîche, accompagnée de toasts grillés."
    ]
  },
  {
    name: "Orange",
    image: `${process.env.PUBLIC_URL}/images/fruits/orange.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-orange.jpg`,
    saison: "décembre, janvier, février, mars",
    aPropos: "Présente sur les étals tout l’hiver, son goût sucré légèrement acidulé excite les papilles. Un concentré d’énergie et de bien-être à croquer et à boire !",
    recolte: "La récolte débute en automne et s’étend, selon les variétés, sur 6 mois environ. Elle peut se faire manuellement, auquel cas il est préférable de couper le fruit avec une paire de ciseaux, en conservant une partie de la tige.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de polyphénols.",
    bienChoisir: "Prenez-la en main. La chair doit être ferme sous les doigts. Pensez à bien lire l’étiquette pour choisir une orange en fonction de l’usage que vous lui réservez : agrume à jus ou de table.",
    conservation: "7 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Les premières traces de l’orange apparaissent en Chine, près de 2 200 ans avant notre ère. Elle acquiert sa popularité en Europe qu’au XV ème siècle grâce aux grands voyages commerciaux.",
    nomRecette: "Carpaccio d'orange",
    recette: [
      "4 oranges",
      "1 c. à soupe de fleur d'oranger", 
      "1 c. à soupe de vinaigre balsamique",
      "quelques belles feuilles de menthe",
      "1 bâton de cannelle",
      "sucre glace"
    ],
    etapesRecette: [
      "Pelez les oranges et enlevez la pellicule recouvrant la pulpe. Coupez-les en tranches.",
      "Mettez les tranches dans un récipient avec la fleur d'oranger et le vinaigre balsamique, filmez et mettez au réfrigérateur pour la nuit.",
      "Dressez les tranches sur une assiette et décorez avec quelques feuilles de menthe.",
    ]
  },
  {
    name: "Pamplemousse",
    image: `${process.env.PUBLIC_URL}/images/fruits/pomelo.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-pamplemousse.jpg`,
    saison: "février, mars, avril, mai, juin",
    aPropos: "Grâce à sa texture juteuse et gourmande, il a très vite conquis les États-Unis puis l’Europe. Cultivé sous les climats chauds, le pamplemousse est encore récolté de façon traditionnelle, à la main.",
    recolte: "La récolte débute aux environs de la fin septembre/début octobre. Le producteur détermine le moment idéal pour ramasser les fruits en procédant à des études : couleur, calibre, tests gustatifs, etc. La récolte est entièrement manuelle et réalisée en plusieurs passages, les fruits n’arrivant pas tous à maturité au même moment.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B6, de vitamine B9 et de vitamine C.",
    bienChoisir: "Choisissez un fruit à la peau bien lisse, ferme et brillante. Il doit également être lourd, ce qui garantit une belle densité de sa pulpe. Et, en fonction de vos préférences gustatives, optez pour un pamplemousse rose ou rouge à la pulpe sucrée ou un pamplemousse jaune, plus acidulé.",
    conservation: "8 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Le pamplemousse contient 92% d’eau, ce qui en fait l’une des plus hautes teneurs en eau de tous les fruits.",
    nomRecette: "Pamplemousse aux crevettes",
    recette: [
      "2 pamplemousses",
      "1 sachet de crevettes décortiquées",
      "1 c. à soupe de mayonnaise",
      "persil haché",
      "sel & poivre"
    ],
    etapesRecette: [
      "Coupez en deux vos pamplemousses.",
      "Détachez complètement la chair des pamplemousses, égouttez le jus et réservez la peau.",
      "Mélangez la chair des pamplemousses avec les crevettes décortiquées, la mayonnaise et le persil haché.",
      "Disposez dans les pamplemousses, décorez éventuellement avec des crevettes non décortiquées. Servez très frais."
    ]
  },
  {
    name: "Papaye",
    image: `${process.env.PUBLIC_URL}/images/fruits/papaye.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-papaye.jpg`,
    saison: "octobre, novembre, décembre",
    aPropos: "Originaire du Mexique, la papaye mûrit sous la chaleur des climats tropicaux. Ce fruit est délicieux nature, gratiné ou assaisonné",
    recolte: "Le fruit, fragile, demande beaucoup de soins et d’attention. Il est difficile de déterminer le moment adéquat pour commencer à récolter, le point de maturité étant peu évident à cerner. Cueilli trop précocement, le fruit se fripe et manque de saveur et de parfum. Et si la papaye est mûre, elle se conserve difficilement",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "La papaye se choisit principalement en fonction de sa couleur. Recouverte d’une peau verte, la papaye est prête à consommer quand cette peau se colore de jaune et que cette teinte recouvre un tiers du fruit.",
    conservation: "7 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Arbre robuste et fécond, le papayer s’est développé en Asie, en Afrique et en Amérique. Sur ce continent, le fruit était utilisé depuis longtemps par les peuples du Sud et les Indiens. Il servait en effet à attendrir les viandes un peu fermes, en râpant la pulpe dessus avant de recouvrir le mélange des feuilles de papayer.",
    nomRecette: "Smoothie papaye-banane",
    recette: [
      "1/2 papaye épluchée, épépinée et coupée en morceaux",
      "1 banane pelée et coupée en rondelles", 
      "20 cl de jus d'orange",
    ],
    etapesRecette: [
      "Mixez la papaye et la banane dans un mixeur.",
      "Ajoutez le jus d'orange puis mixez à nouveau jusqu'à l'obtention d'une texture lisse et onctueuse.",
      "Servez bien frais votre smoothie papaye-banane, décoré de noix de coco râpée."
    ]
  },
  {
    name: "Pastèque",
    image: `${process.env.PUBLIC_URL}/images/fruits/pasteque.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-pasteque.jpg`,
    saison: "mai, juin, juillet, aout, septembre",
    aPropos: "Apparue sous le soleil d’Afrique, la pastèque est délicieuse nature, gratinée ou assaissonée ! Sa chair sucrée et parfumée est un plaisir gustatif.",
    recolte: "De la même famille que les courges, la pastèque se présente sous la forme d’une plante à tiges rampantes qui peut atteindre plusieurs mètres de long. Pour ne pas épuiser la plante, seuls trois ou quatre fruits sont conservés.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine C et de polyphénols.",
    bienChoisir: "Elle doit être lourde et ferme, et l’écorce, lisse et brillante. Une belle tache jaune sur la peau signifie que le fruit a bien pris le soleil et qu’il est donc parfaitement mûr.",
    conservation: "7 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Avec cette invention, les Japonais ont élevé le gain de place et le stockage au rang d’œuvre d’art. Des agriculteurs dans le sud de l’archipel ont ainsi eu l’idée de cultiver des pastèques carrées, facilement empilables. Comment ? En faisant pousser le fruit dans un bocal de verre. Au fur et à mesure de sa croissance, la pastèque épouse naturellement la forme du récipient.",
    nomRecette: "Pastèque glacée",
    recette: [
      "500 g de pastèque",
      "4 boules de sorbet au citron", 
      "le jus d’une orange",
      "1 c. à café de sirop de grenadine",
    ],
    etapesRecette: [
      "Préparez la pastèque : épépinez-la, puis coupez-la en dés.",
      "Mettez-la dans un bol à mixeur avec les boules de sorbet citron.",
      "Ajoutez-y le jus d’orange et la grenadine.",
      "Mixez pendant 30 sec. à grande vitesse",
      "Versez dans des verres individuels puis servez."
    ]
  },
  {
    name: "Pêche",
    image: `${process.env.PUBLIC_URL}/images/fruits/peche.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-peche.jpg`,
    saison: "juin, juillet, aout, septembre",
    aPropos: "Sucrée et très désaltérante, elle fait le régal des gourmands à la belle saison. Elle se consomme nature mais se marie également à de savoureux plats et desserts. La pêche-nectarine est le 7e fruit le plus consommé en France !",
    recolte: "Le pêcher ne donne des fruits qu’au bout de 7 ans. Sa durée de vie varie de 15 à 20 ans. La récolte s’étend de juillet à septembre et se fait à la main. Le pêcher ne donne des fruits que pendant une dizaine de jours.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine E, de polyphénols et de potassium.",
    bienChoisir: "Lorsqu’elle est mûre, la pêche est souple. Sa peau ne doit pas présenter de taches ni de meurtrissures et surtout, elle exhale une odeur parfumée.",
    conservation: "7 jours à température ambiante.",
    nutriscore: "A",
    funFact: "La pêche acquit ses titres de noblesse à Versailles, dans le jardin fruitier du Roi Soleil. Plus de trente variétés y furent cultivées. Parmi elles, on compte celles aux noms évocateurs tels « Belle de Chevreuse », « Belle de Vitry », ou encore « Téton de Vénus » !",
    nomRecette: "Vin de pêche",
    recette: [
      "75 cl de vin blanc",
      "20 feuilles jeunes de pêché", 
      "15 morceaux de sucre",
      "1 gousse de vanille",
      "1 pincée de cannelle en poudre",
      "1 pincée de muscade en poudre"
    ],
    etapesRecette: [
      "Nettoyez et épongez les feuilles de pêché.",
      "Versez le vin blanc dans un grand bocal fermant hermétiquement.",
      "Ajoutez les feuilles de pêché, les morceaux de sucre, la gousse de vanille, la cannelle et la muscade en poudre. Mélangez bien puis fermez le bocal avec le couvercle.",
      "Laissez macérer le vin de pêche pendant 48 heures minimum, dans un endroit sec à l’abri de la chaleur, en remuant de temps à autre.",
      "Au bout de ce temps, filtrez le vin de pêche ainsi obtenu et mettez-le en bouteilles. Bouchonnez.",
      "Laissez macérer le vin de pêche pendant 1 mois minimum, toujours dans un endroit sec à l’abri de la chaleur, avant la dégustation.",
      "Dégustez ce vin de pêche avec modération à l’apéritif"
    ]
  },
  {
    name: "Poire",
    image: `${process.env.PUBLIC_URL}/images/fruits/poire.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-poire.jpg`,
    saison: "aout, septembre, octobre, novembre, décembre, janvier, février, mars, avril",
    aPropos: "Disponible quasiment toute l’année, elle se décline en une dizaine de variétés qui se répartissent entre l’été et la saison d’automne-hiver. Dégustez-la à la croque ou dévoilez toute sa douceur dans de nombreux desserts ou dans des recettes salées.",
    recolte: "La récolte s’effectue lorsque les fruits sont encore fermes. Pour les espèces d’automne et d’hiver, elle se fait dès septembre. Les poires d’été se récoltent entre la mi-juillet et la mi-septembre.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de cuivre et de polyphénols.",
    bienChoisir: "La peau de la poire doit être lisse et bien tendue. De plus, son pédoncule doit être présent et bien attaché. Si vous achetez une poire d’hiver, elle doit être ferme. Elle continuera sa maturation chez vous.",
    conservation: "3 jours à température ambiante.",
    nutriscore: "A",
    funFact: "La poire est apparue en Asie centrale. En Chine, sa culture aurait commencé plus de 4 millénaires avant notre ère ! Elle traverse ensuite le continent asiatique pour atteindre l’Europe où les Grecs et les Romains furent les premiers à l’adopter. Dans la Rome Antique, la poire se consomme déjà crue, cuite ou séchée.",
    nomRecette: "Tarte aux poires Williams",
    recette: [
      "1 boîte de poires Williams",
      "1 pâte brisée en rouleau",
      "1 brique de crème fraîche",
      "3 cuillères à soupe de sucre en poudre",
      "2 oeufs"
    ],
    etapesRecette: [
      "Préchauffez votre four à 210°C.",
      "Commencez par dérouler et déposer la pâte brisée dans un plat à tarte, et piquez le fond de la pâte avec une fourchette.",
      "Recouvrez le fond de votre tarte de papier sulfurisé et mettez du riz ou des haricots blancs crus dessus pour la tenir afin de faire cuire la pâte à blanc.",
      "Laissez cuire 10 minutes, puis retirez le papier sulfurisé et le riz. Baissez la température du four thermostat 6 ou 180°C et remettez la pâte à cuire durant 5 minutes.",
      "Ensuite, lavez et pelez les poires Williams puis découpez-les en tranches ou en morceaux grossiers et déposez-les sur le fond de la pâte à tarte.",
      "Dans un bol, mélangez ensuite les deux œufs, la brique de crème fraîche et les 3 cuillères de sucre en poudre pour obtenir un mélange lisse et homogène. Versez ensuite l’appareil sur les poires Williams afin de les recouvrir",
      "Si vous le souhaitez, vous pouvez agrémenter votre tarte aux poires Williams de morceaux d'amandes effilées pour un peu plus de gourmandise",
      "Mettez votre tarte aux poires Williams à cuire au four durant environ 45 minutes, le temps qu’elle soit dorée à souhait sur le dessus et que la pâte brisée soit bien cuite. Une fois la cuisson terminée, sortez votre tarte aux poires Williams du four et laissez tiédir avant de la servir"
    ]
  },
  {
    name: "Pomme",
    image: `${process.env.PUBLIC_URL}/images/fruits/pomme.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-pomme.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier, février, mars, avril, mai",
    aPropos: "Ses techniques de production se sont perfectionnées au cours des siècles, offrant un large éventail de variétés et de saveurs. La pomme rythme tous les moments de la journée, croquée au naturel ou cuisinée pour des mets sucrés ou salés.",
    recolte: "Selon leur degré de maturité (déterminé par leur teneur en amidon), les pommes sont cueillies à maturité de consommation pour une consommation immédiate, ou à maturité de cueillette pour être d’abord stockées. La cueillette s’effectue en détachant le fruit de la branche avec son pédoncule.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de potassium et de flavonoïdes.",
    bienChoisir: "La pomme doit être ferme et parfumée, sa peau lisse et sans tache sombre. Une variété adaptée à votre envie de préparation, crue la douceur de la Golden, le croquant de la Gala, l’acidulé de la Granny Smith, la fraîcheur de la Fuji.",
    conservation: "8 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Apparue sur Terre il y a quelque quatre-vingt millions d’années, la pomme poussait à l’état sauvage dans le sud du Caucase jusqu’au Sinkiang (Ouest de la Chine). Savourée dès la fin de la Préhistoire, la pomme accompagne l’homme dans son histoire. Pas étonnant qu’elle soit si riche en symboles.",
    nomRecette: "Chaussons aux pommes",
    recette: [
      "500g de pommes (Golden ou Reinettes)",
      "250g de pâte feuilletée",
      "20g de beurre",
      "50g de sucre",
      "2 jaunes d'oeuf"
    ],
    etapesRecette: [
      "Épluchez et coupez les pommes en tranches. Puis dans une casserole, faites fondre le beurre, et ajoutez-y les pommes. Laissez-les cuire à feu doux 15 min. Retirez la casserole du feu et écrasez les morceaux de pomme grossièrement. Incorporez le sucre en poudre tout en mélangeant. Réservez.",
      "Découpez 6 ronds de pâte feuilletée avec un emporte-pièce (ou un grand bol). Étendez légèrement les cercles au rouleau pour qu'ils soient ovales.",
      "Déposez de la compote sur la moitié des ovales en veillant à laisser 1 cm sur les bords. Battez le jaune d'oeuf avec une pincée de sel et badigeonnez-en sur les bords des ovales. Puis pliez-les en 2 en soudant bien les bords l'un contre l'autre.",
      "Incisez légèrement le dessus des chaussons. Laissez-les refroidir 30 min au réfrigérateur. Préchauffez le four à 210°C.",
      "Beurrez une plaque allant au four et disposez-y les chaussons. Badigeonnez-les de jaune d'oeuf battu à l'aide d'un pinceau. Enfournez 15 min : si à l'issue de ces 15 min les chaussons ne sont pas assez dorés, baissez la température à 180°C et sortez-les lorsqu'ils sont à votre goût."
    ]
  },
  {
    name: "Prune",
    image: `${process.env.PUBLIC_URL}/images/fruits/prune.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-prune.jpg`,
    saison: "juillet, aout, septembre",
    aPropos: "Juteuse et désaltérante, la prune est l’alliée fraîcheur de l’été ! Relativement peu calorique, elle peut se consommer sans problème à tout moment de la journée.",
    recolte: "Les premières fleurs commencent à apparaître sur les branches au début du printemps. Mais il faut attendre la chaleur du mois de juillet avant de pouvoir cueillir les premiers fruits. Les variétés les plus tardives peuvent même se faire désirer jusqu’en septembre !",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine B9 et de vitamine E.",
    bienChoisir: "Au toucher, les fruits doivent être souples, sans être mous. La peau est lisse, sans tache, et dégage un bon parfum.",
    conservation: "10 jours au réfrigérateur.",
    nutriscore: "A",
    funFact: "La culture des prunes semble, en effet, très ancienne. Les archéologues ont retrouvé des noyaux proches de ceux de la mirabelle dans des sites datant de l’âge de bronze. On sait aussi qu’en Égypte, des prunes séchées étaient placées dans les tombeaux des pyramides.",
    nomRecette: "Crumble aux prunes",
    recette: [
      "500g de prunes",
      "200g de beurre mou",
      "200g de farine",
      "160g de sucre",
      "150g d'amandes en poudre"
    ],
    etapesRecette: [
      "Préchauffez votre four à 200°C et posez votre Flexipat sur la plaque perforée.",
      "Mélangez le beurre, le sucre, la farine et les amandes en poudre pour obtenir une pâte de crumble. Elle doit être friable. Versez trois quarts sur la Flexipat et tassez avec une cuillére.",
      "Lavez les prunes, enlevez les noyaux et coupez les en quarts. Posez les sur le fond de pâte et saupoudrez avec le crumble restant. Faites cuire environ 20 – 25 min à 200°C.",
    ]
  },
  {
    name: "Raisin",
    image: `${process.env.PUBLIC_URL}/images/fruits/raisin.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-raisin.jpg`,
    saison: "aout, septembre, octobre",
    aPropos: "En France, le raisin fait partie des fruits les plus appréciés, pourtant, le fruit de la vigne a d’abord été présent à l’état sauvage dans de nombreuses parties du monde, avant d’être domestiqué !",
    recolte: "En pratique, les cueilleurs coupent les grappes et retirent aussitôt les grains flétris, mal colorés, éclatés ou desséchés à l’aide d’une paire de ciseaux. La récolte peut également s'effectuer au moyen de machine à vendanger !",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de cuivre, de polyphénols et de potassium.",
    bienChoisir: "Choisissez les raisins avec des grains fermes, non ridés et non tachés. Prenez-les bien mûrs : une fois coupés, ils ne mûrissent plus. La tige doit être verte, souple et cassante, sans être sèche.",
    conservation: "5 jours au réfrigérateur.",
    nutriscore: "A",
    funFact: "Si, pendant longtemps, le raisin sera seulement utilisé pour produire du vin, en France, on commencera à le consommer en bouche vers le 16e siècle, grâce notamment à François 1er qui en fera l’un de ses desserts favoris !",
    nomRecette: "Muffins aux raisins",
    recette: [
      "4 oeufs",
      "2 tasses de sucre", 
      "2 tasse de citrouille précuite ou râpée",
      "3 tasses de farine de blé entier",
      "1 c. à soupe de cannelle",
      "2 c. à soupe de poudre à pâte",
      "2 c. à café de soda à pâte",
      "2 tasses de raisins secs",
      "cassonade",
      "1 tasse et demi d'huile végétale",
      "1 c. à café de sel"
    ],
    etapesRecette: [
      "Préchauffez le four th.6/7 (190ºC).",
      "Battez légèrement les oeufs, ajoutez le sucre, la citrouille (précuite) ou râpée et mélangez bien.",
      "Ajoutez la farine de blé entier, la cannelle, la poudre à pâte, le soda à pâte, le sel et liez bien le tout en ajoutant les raisins secs.",
      "Remplissez au 2/3 les moules déjà graissés et saupoudrez un peu de cassonade sur chaque muffin. Enfournez pendant 15 min.",
    ]
  },
]