export const LegumeData = [
  {
    name: "Ail",
    image: `${process.env.PUBLIC_URL}/images/legumes/ail.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-ail.jpg`,
    saison: "juillet, aout, septembre, octobre, novembre, décembre",
    aPropos: "Consommé depuis des millénaires, l’ail est un condiment très apprécié. Sa culture, très simple, ne demande pas d’attention particulière et les espèces produites en France proposent un large éventail de goûts.",
    recolte: "Lorsque les feuilles fanent, la récolte commence. C'est généralement pendant l'été. L'ail est ensuite séché en plein air jusqu'à ce que sa tige soit cassante.",
    bienfaits: "Ce condiment sublime et relève les saveurs, qu'il soit jaune, blanc ou rouge. Ce légume est composé d'eau, de glucides, de fibres, de la vitamine B6, de phospore et de potassium.",
    bienChoisir: "Examinez la tête de l'ail, elle doit être renflée ! Si des petits germes verts dépassent de la gousse, cela indique que l'ail n'est plus frais.",
    conservation: "6 mois à 1 an à l'abri de la lumière.",
    nutriscore: "A",
    funFact: "L’ail appartient à la famille botanique des liliacées, comme la ciboulette et l’oignon, mais aussi comme le lys et la tulipe !",
    nomRecette: "Croûtons à l'ail",
    recette: [
      "2 gousses d'ail",
      "6 tranches de pain", 
      "4 c. à soupe d'huile d'olive",
      "1 pincée de sel"
    ],
    etapesRecette: [
      "Épluchez les gousse d'ail et frottez les sur les tranches de pain.",
      "Découpez le pain en petits dés. Dans une poêle avec l'huile d'olive bien chaude, mettez les restes de gousse d'ail. Faîtes revenir les dés de pain dans la poêle à feu moyen, jusqu'à ce qu'ils soient dorés.",
      "Servez bien chauds sur une soupe ou une salade pour y rajouter un peu de croquant et de gourmandise."
    ]
  },
  {
    name: "Artichaut",
    image: `${process.env.PUBLIC_URL}/images/legumes/artichaud.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-artichaut.jpg`,
    saison: "mai, juin, juillet, aout, septembre, octobre, novembre",
    aPropos: "Originaire du bassin méditerranéen, l’artichaut est le résultat de différents croisements botaniques. Les premières traces de ce légume sont relevées en Italie, en pleine Renaissance (milieu du XVIe siècle).",
    recolte: "Les plants font près d'un mètre de hauteur. La récolte s'effectue exclusivement à la main, de fin mai à fin novembre.",
    bienfaits: "Sa chair, délicate et généreuse, est gorgée d'antioxydants. Ce légume est composé d'eau, de glucides, de fibres, de la vitamine B9 et de potassium.",
    bienChoisir: "Examinez l'artichaut dans son ensemble. Il doit présenter des écailles non tachées et bien serrées, si la tige est légèrement humide, c'est un gage de fraîcheur !",
    conservation: "Quelques jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Ce que l’on appelle communément le coeur ou le fond d’artichaut n’est autre que le réceptacle des fleurs non épanouies de la plante. Ces dernières forment alors le foin, sorte de poils qui recouvre le coeur d’artichaut.",
    nomRecette: "Tapenade d'artichauts au persil",
    recette: [
      "400g de coeurs d'artichaut", 
      "2 gousses d'ail", 
      "1 c. à soupe d'huile d'olive", 
      "1 c. à soupe de jus de citron", 
      "1 c. à soupe de persil", 
      "sel & poivre"
    ],
    etapesRecette: [
      "Égouttez les coeurs d'artichaut, Épluchez et coupez en fins morceaux les gousses d'ail. Ajoutez le tout dans un bol.", 
      "Incorporez l'huile d'olive, le jus de citron et le persil. Salez et poivrez.", 
      "Hacez grossièrement à la fourchette le mélange. Mixez ensuite pendant quelques secondes jusqu'à l'obtention d'une purée lisse sans grumeaux.", 
      "Conservez au réfrigérateur jusqu'au service", 
      "Dégustez sur des tranches de pain grillé."
    ]
  },
  {
    name: "Asperge",
    image: `${process.env.PUBLIC_URL}/images/legumes/asperge.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-asperge.jpg`,
    saison: "avril, mai, juin",
    aPropos: "Blanche, violette ou verte, l’asperge est un délice de printemps à ne pas manquer. Longtemps considérée comme un produit de luxe, elle est aujourd’hui beaucoup plus abordable.",
    recolte: "Les producteurs cueillent les asperges à la main, une par une, à l’aide d’un couteau. Il faut aller très vite : l’asperge peut pousser jusqu’à 15 cm en une journée ! Pour être sûr de les cueillir au bon moment, les producteurs passent tous les jours au même endroit.",
    bienfaits: "L’asperge affectionne particulièrement les sols sablonneux. Ce légume est composé d'eau, de glucides, de fibres, de la vitamine B9 et de cuivre.",
    bienChoisir: "Examinez l'asperge dans son ensemble, la tige doit être droite, lisse et presque cassante, le bourgeon doit présenter des écailles serrées et le talon doit être légèrement humide et brillant.",
    conservation: "3 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Raffinée, l’asperge est restée pendant longtemps un légume cher. En France, elle est plus accessible depuis le 19è siècle.",
    nomRecette: "Omelette aux asperges",
    recette: [
      "1 botte d'asperges vertes", 
      "8 oeufs", 
      "1 c. à soupe d'huile de tournesol", 
      "1 c. à café de crème fraîche", 
      "sel & poivre"
    ],
    etapesRecette: [
      "Coupez la base dure des asperges. Lavez et épongez-les, coupez-les en rondelles épaisses.",
      "Faites chauffez l’huile dans une poêle anti-adhésive et ajoutez toutes les asperges. Salez, poivrez et couvrez. Laissez cuire 5 à 8 min afin qu’elles soient cuites mais encore croquantes. Poursuivez la cuisson 5 min à découvert.",
      "Battez les œufs en omelette. Incorporez la crème en continuant à battre. Salez, poivrez. Faites glissez le mélange sur les asperges dans la poêle et laissez cuire sur feu vif. Au fur et à mesure de la cuisson, baissez le feu et rabattez les bords de l’omelette vers le centre.",
      "Faites glisser l’omelette sur un plat et servez aussitôt."
    ]
  },
  {
    name: "Aubergine",
    image: `${process.env.PUBLIC_URL}/images/legumes/aubergine.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-aubergine.jpg`,
    saison: "juin, juillet, aout, septembre",
    aPropos: "Ce légume se prête à tous types de cuisson et accompagne à merveille tous vos petits plats. La plus fréquente sur les étals est l’aubergine violette, à la chair blanche et moelleuse.",
    recolte: "La récolte de l’aubergine a lieu au début du mois de juillet, au rythme de 2 ou 3 fois par semaine, quand elle est d’un beau violet profond uniforme.",
    bienfaits: "Sa culture réclame des attentions particulières pour produire des légumes goûteux et de qualité. Ce légume est composé d'eau, de glucides, de fibres, de la vitamine B5 et de potassium.",
    bienChoisir: "L'aubergine doit être ferme avec une peau brillante et lisse. Son pédoncule doit être toujours vert.",
    conservation: "Dans un endroit frais entre 10 et 12°C.",
    nutriscore: "A",
    funFact: "La culture de l’aubergine est très ancienne. Elle remonte en effet à 800 ans avant J-C, dans la région indo-birmane.",
    nomRecette: "Caviar d'aubergines",
    recette: [
      "3 aubergines", 
      "2 gousses d'ail", 
      "1 c. à soupe de jus de citron", 
      "2 c. à soupe d'huile d'olive", 
      "sel & poivre"
    ],
    etapesRecette: [
      "Préchauffez votre four à 210°C.",
      "Coupez les aubergines en deux et quadrillez la chair. Salez, poivrez puis faites cuire les aubergines 30min au four.",
      "Une fois cuites, enlevez la peau et déposez-les dans un grand saladier. Écrasez la chair à l'aide d'une fourchette ou d'un mixeur.",
      "Hachez les gousses d'ail très finement, puis ajoutez-les aux aubergines avec l'huile d'olive. Incorporez le sel, le poivre et le jus de citron",
      "Laissez reposer 1h à 1h30 au réfrigérateur.",
      "Dégustez avec du pain grillé."
    ]
  },
  {
    name: "Betterave",
    image: `${process.env.PUBLIC_URL}/images/legumes/betterave.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-betterave.jpg`,
    saison: "octobre, novembre, décembre, janvier, février, mars",
    aPropos: "Son goût très doux et sucré, délicat, est apprécié en crudités ou cuisiné et accommodé.",
    recolte: "La betterave est une plante rustique. Si sa culture ne réclame pas de soins particuliers, il n’en va pas de même après la récolte, où le légume va faire l’objet de contrôles attentifs.",
    bienfaits: "Lavées et épluchées à la vapeur, les betteraves sont ensuite cuites, emballées sous vide, et enfin pasteurisées ou stérilisées, pour garantir leur bonne conservation. Ce légume est composé d'eau, de glucides, de fibres, de chlorure et de potassium.",
    bienChoisir: "On distingue la betterave crue et cuite. La crue doit présenter une peau à peine desséchée tansi que la cuite doit être lisse sans dessèchement.",
    conservation: "5 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "La betterave et la blette , bien que totalement dissemblables, sont cousines. Toutes deux sont nées de l’évolution de la blette maritime, qui poussait à l’état sauvage sur les rivages de la Méditerranée et de l’Atlantique.",
    nomRecette: "Dip de betterave",
    recette: [
      "500g de betteraves cuites", 
      "70g de tahini (crème de sésame)", 
      "1 gousse d'ail", 
      "1 citron jaune",
      "2 c. à soupe de cumin",
      "sel & poivre"
    ],
    etapesRecette: [
      "Coupez les betteraves en morceaux grossiers tout en conservant le jus.",
      "Mixez les betteraves le plus finement possible puis ajoutez, tout en mélangeant, le tahini, l'huile d'olive et le jus de citron.",
      "Pelez l'ail et pressez-le ou hachez-le le plus finement possible. Ajoutez-le à la préparation.",
      "Ajoutez le cumin, le sel et le poivre.",
      "Conservez 1h au frigo avant de servir."
    ]
  },
  {
    name: "Blette",
    image: `${process.env.PUBLIC_URL}/images/legumes/blette.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-blette.jpg`,
    saison: "juillet, aout, septembre, octobre, novembre",
    aPropos: "Très appréciée pour sa saveur douce et sa texture fondante, la blette est comsommée depuis l'Antiquité.",
    recolte: "Les semis de blette sont mis en terre en avril puis récoltés deux mois et demi après, lorsqu'elles atteignent la maturité.",
    bienfaits: "Des blettes sauvages peuvent être récoltées de la fin de l’été à celle de l’automne principalement dans la moitié sud de la France. Ce légume est composé d'eau, de fibres, de provitamine A Béta-carotène, de vitamine K1, de manganèse et de chlorure.",
    bienChoisir: "Des blettes fraiches ont des feuilles non déchirées au vert intense et uniforme.",
    conservation: "5 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Des traces de culture et de consommation ont été trouvées tant en Mésopotamie que dans la Rome Antique.",
    nomRecette: "Moelleux de blettes",
    recette: [
      "3 feuilles de blettes", 
      "1 oeuf", 
      "noix de muscade", 
      "1 c. à café de maïzena",
      "sel & poivre"
    ],
    etapesRecette: [
      "Préchauffez le four thermostat 6 à 180°.",
      "Faites bouillir de l'eau et plongez-y les feuilles pendant 10 minutes. Égouttez-les et placez-les dans un blender.",
      "Ajoutez l'œuf, la maïzena, la muscade, sel et poivre. Mixez le tout.",
      "Versez la préparation dans les moules et enfournez pour 20 min.",
      "A dégustez chaud."
    ]
  },
  {
    name: "Brocoli",
    image: `${process.env.PUBLIC_URL}/images/legumes/brocoli.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-brocoli.jpg`,
    saison: "juin, juillet, aout, septembre, octobre, novembre",
    aPropos: "Ce grand parent du chou-fleur est présent depuis quelques dizaines d’années sur les étals français, et a très vite su se faire une place dans les assiettes. Son goût très doux et son croquant font des merveilles dans tous types de recettes salées.",
    recolte: "Le brocoli est récolté à la main, avant que les inflorescences ne s’ouvrent et se parsèment de petites fleurs jaunes, entre avril et novembre. Le chou est coupé ; sont laissés de côté trognon et feuilles extérieures.",
    bienfaits: "Gorgé de vitamines, ce légume vert participe en plus à votre bien-être. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de vitamine K1.",
    bienChoisir: "La tige du brocolis ne doit pas être sèche et les boutons doivent être serrés et de couleur uniforme.",
    conservation: "5 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Création des Romains, le brocoli a été développé à partir des plus beaux spécimens de chou sauvage. Il s’agit même de l’étape intermédiaire avant la découverte du chou-fleur.",
    nomRecette: "Brocolis vapeur",
    recette: [
      "500g de brocolis", 
      "3 pincées d'herbe de provence", 
      "90g de lait ou boisson végétale", 
      "sel & poivre"
    ],
    etapesRecette: [
      "Détaillez les brocolis en petits bouquets, lavez-les et égouttez-les.",
      "Faitez cuire à la vapeur jusqu'à ce que la pointe d'un couteau les transperce facilement.",
      "Tips : Pour rajouter un côté croquant au brocolis, saisissez-les à feu moyen dans une poêle.",
      "Servez bien chaud.",
    ]
  },
  {
    name: "Carotte",
    image: `${process.env.PUBLIC_URL}/images/legumes/carotte.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-carotte.jpg`,
    saison: "aout, septembre, octobre, novembre, décembre, janvier, février, mars",
    aPropos: "Toujours disponible et très bon marché, la carotte est aujourd’hui le deuxième légume le plus consommé en France, juste derrière la tomate.",
    recolte: "Les carrotes sont récoltés de juin à mai de l'année suivante. À partir de novembre, les carottes de garde sont “retournées” dans les champs, passant ainsi de la position verticale à la position couchée, et recouvertes de terre, et parfois également d’une couche de paille, afin d’être protégées du froid.",
    bienfaits: "La carotte renferme de très nombreux minéraux et vitamines ! Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène et de vitamine B9.",
    bienChoisir: "Une carrote fraîche possède une couleur intense et une belle brillance. Si elle possède des fanes, ces dernières doivent être bien vertes.",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "La carotte poussait à l’état sauvage en Asie Mineure, il y a déjà plus de deux mille ans. Sa domestication est relativement récente puisqu'elle aurait eu lieu entre le VI ème et IX ème siècle.",
    nomRecette: "Carottes râpées",
    recette: [
      "3 belles carottes", 
      "1 échalote",
      "persil", 
      "vinaigrette", 
      "sel & poivre"
    ],
    etapesRecette: [
      "Lavez vos carottes puis épluchez-les avant de les râper dans une assiette.",
      "Hachez finement l'échalotte et rajoutez-la dans vos carottes puis parsemez un peu de sel et ajouter la vinaigrette.",
      "Mélangez puis ajouter le persil, soit en petites branches pour la présentation, soit coupé fin pour l'intégrer aux carottes."
    ]
  },
  {
    name: "Céleri",
    image: `${process.env.PUBLIC_URL}/images/legumes/celeri.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-celeri.jpg`,
    saison: "juin, juillet, aout, septembre, octobre, novembre",
    aPropos: "Ce légume rustique s’est développé et a imposé sa présence sur les étals grâce à sa chair savoureuse, croquante quand elle est crue, fondante après cuisson.",
    recolte: "Les plants sont récoltés manuellement à partir de juillet pour les plus précoces, à partir de la mi-septembre pour le gros de la production.",
    bienfaits: "Le céleri est très concentré en vitamines et minéraux essentiels, tout en étant très peu énergétique. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B6, de vitamine B9 et de potassium.",
    bienChoisir: "La peau du céleri doit être uniformément pâle sans taches brunes ou jaunes. Son poids doit être assez lourd.",
    conservation: "6 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Le céleri a d'abord été utilisé pour ses vertus médicinales plutôt que gustatives : guérison de la mélancolie, choix du sexe d'un enfant à naître ou encore lutte contre les maux de dents ...",
    nomRecette: "Chips de céleri",
    recette: [
      "1 céleri",
      "1 c. à café de curry en poudre",
      "1 c. à café de paprika",
      "huile de friture",
      "sel"
    ],
    etapesRecette: [
      "Épluchez le céleri, lavez-le et séchez-le soigneusement.",
      "Taillez-le en lamelle très fines de préférence au robot ou à la mandoline.",
      "Faites chauffer l'huile dans une grande casserole.",
      "Mélangez le paprika et le curry puis roulez les lamelles dans les épices.",
      "Plongez les lamelles dans l'huile par petites quantités. Laissez frire 1 min puis retirez les chips avec une écumoire. Égouttez-les sur du papier absorbant.",
      "Répétez l'étape précédente jusqu'à épuisement des lamelles. Servez à l'apéritif."
    ]
  },
  {
    name: "Champignon de Paris",
    image: `${process.env.PUBLIC_URL}/images/legumes/champi.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-champi.jpg`,
    saison: "janvier, février, mars, avril, mai, juin, juillet, aout, septembre, octobre, novembre, décembre",
    aPropos: "Les champignons de Paris sont à distinguer des champignons sauvages, dont la présence, sujette aux aléas de la météo, est irrégulière et le plus souvent très courte.",
    recolte: "La récolte est généralement réalisée en 3 « volées » (cycles de pousse). Pendant cette période les champignons poussent de manière cyclique avec des phases de « repos végétatif ». Les champignons frais sont cueillis à la main. Seul un homme peut juger de la maturité d’un champignon et le cueillir au bon moment.",
    bienfaits: "Ces petits bouchons  gorgés de vitamines sont délicieux cuisinés simplement. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B3, de vitamine B5 et de cuivre.",
    bienChoisir: "Choisissez des champignons aux couleurs franches et dépourvus de tâches, lisses, soyeux et surtout non visqueux.",
    conservation: "4 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "On a beau les classer parmi les légumes, les champignons cultivés ne sont ni des animaux, ni des végétaux.",
    nomRecette: "Sauce aux champignons",
    recette: [
      "300g de champignons de Paris",
      "1 échalote",
      "2 c. à soupe de crème",
      "1/2 bouillon de volaille",
      "30g de beurre ou huile",
      "2 à 3 c. à soupe de farine",
      "sel & poivre"
    ],
    etapesRecette: [
      "Faites chauffer le beurre ou l'huile dans une casserole à feu doux. Émincez l'échalote, lavez, épluchez et émincez les champignons. Faites-les revenir pendant quelques minutes.",
      "Ajoutez la farine de façon à faire un roux. Mélangez puis retirez la casserole du feu.",
      "Mouillez avec le bouillon et portez à ébullition en remuant régulièrement. Laissez mijoter quelques minutes puis ajoutez la crème fraîche liquide.",
      "Salez et poivrez à votre convenance.",
      "Servez chaud avec votre viande, pâtes, riz ou petits légumes."
    ]
  },
  {
    name: "Chou",
    image: `${process.env.PUBLIC_URL}/images/legumes/chou.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-chou.jpg`,
    saison: "octobre, novembre, décembre, janvier, février, mars, avril",
    aPropos: "À chaque région de France sa spécialité à base de chou. Longtemps associé à des plats roboratifs, le chou retrouve aujourd’hui toute sa place dans des préparations légères et raffinées.",
    recolte: "La récolte débute à l’automne, dès que les températures commencent à baisser et que le coeur du chou a pris la taille d’une belle pomme.",
    bienfaits: "Le chou se prête à de multiples recettes et présente de sérieuses qualités nutritionnelles. Ce légume est composé d'eau, de glucides, de fibres, de vitamine C et de vitamine K1.",
    bienChoisir: "Le chou doit être à la fois lourd et dense. Ses feuilles doivent être bien serrées, craquantes et brillantes.",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Le chou est un symbole de fécondité. Selon la légende populaire, les garçons naissent dans des choux, et les filles dans des roses.",
    nomRecette: "Roulés au chou",
    recette: [
      "1 chou vert",
      "2 courgettes",
      "2 carottes",
      "200g de champignons",
      "350g de dés de jambon fumé",
      "1/2 citron",
      "1 noix de beurre",
      "sel & poivre"
    ],
    etapesRecette: [
      "Retirez le pied terreux des champignons, lavez-les et émincez-les en fines rondelles.",
      "Lavez soigneusement le chou et choisissez 12 petites feuilles.",
      "Epluchez les carottes, lavez les courgettes et râpez ces deux légumes.",
      "Pressez le demi-citron.",
      "Faites blanchir quelques min les feuilles de chou à l’eau bouillante.",
      "Dans une poêle, faites revenir les champignons quelques min dans un peu de margarine avec le jus de citron.",
      "Sur les feuilles de choux, déposez les carottes et les courgettes râpées, les champignons et ajoutez les dés de jambon fumé. Salez, poivrez.",
      "Roulez les feuilles de choux en forme de fagots et maintenez-les avec un pic en bois.",
      "Faites cuire ces roulés 15 min à la vapeur et consommez-les très chauds accompagnés d’une sauce aigre douce que vous trouverez dans le commerce."
    ]
  },
  {
    name: "Chou fleur",
    image: `${process.env.PUBLIC_URL}/images/legumes/chou-fleur.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-choufleur.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier, février, mars",
    aPropos: "Longtemps oublié, le chou-fleur a fait son grand retour en Europe au XVIe siècle. Apprécié des rois de France, il est ensuite devenu très populaire.",
    recolte: "Lorsque les choux fleurs ont atteint le calibre voulu, ils sont coupés au couteau à la fraîche, puis conditionnés en plateau dans les champs et réfrigérés.",
    bienfaits: "Le chou fleur se prête à de multiples recettes et présente de sérieuses qualités nutritionnelles. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B5, de vitamine B9 et de vitamine C.",
    bienChoisir: "Sans feuilles, ses fleurettes doivent être serrées et blanche, sans comporter de tâches. Avec les feuilles, ces dernières doivent être de couleur vert pâle et non flétries.",
    conservation: "3 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Le chou-fleur est probablement originaire du Proche-Orient. Les Grecs et les Romains le connaissent et l’apprécient dès l’Antiquité. Mais celui-ci tombe ensuite dans l’oubli pendant une longue période.",
    nomRecette: "Beignets de chou fleur",
    recette: [
      "1 chou fleur",
      "3 oeufs",
      "150g de farine",
      "10cl de lait",
      "persil frais",
      "1 c. à café de curry en poudre",
      "sel & poivre"
    ],
    etapesRecette: [
      "Détaillez le chou fleur en bouquets. Placez-les dans une casserole remplie d'eau bouillante salée. Faites cuire une dizaine de minutes. Égouttez bien et réservez.",
      "Dans un grand saladier, cassez les œufs. Ajoutez la farine en pluie tout en fouettant énergiquement pour éviter les grumeaux. Ajoutez le curry et, un peu de poivre et de persil finement ciselé.",
      "Incorporez le lait progressivement. La quantité de lait est à ajuster selon la conscience de la pâte. Elle doit napper une cuillère. Réservez la pâte à beignets.",
      "Faites chauffer l'huile dans votre friteuse ou dans une grande poêle. Trempez les bouquets de chou fleur dans la pâte à beignets. Plongez-les dans l'huile bine chaude.",
      "Faites cuire quelques minutes jusqu'à ce que les beignets soient bien dorés. Égouttez et déposez sur du papier absorbant. Dégustez chaud",
    ]
  },
  {
    name: "Chou rave",
    image: `${process.env.PUBLIC_URL}/images/legumes/chou-rave.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-chourave.jpeg`,
    saison: "avril, mai, juin, juillet, aout, septembre",
    aPropos: "Le chou-rave est un légume que l'on consomme le plus souvent en hiver et qui se marie très bien avec les pommes de terre et les carottes dans les soupes et les ragoûts.",
    recolte: "La récolte débute à l’automne, dès que les températures commencent à baisser et que le coeur du chou a pris la taille d’une belle pomme.",
    bienfaits: "Le chou rave présente de belles qualités nutritionnelles. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "Choisissez de préférence des pommes de choux-raves de 5 cm à 7 cm de diamètre. Plus gros, ils risquent d'être fibreux.",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "En Amérique du Nord, malgré de timides efforts pour le faire connaître, il reste le parent pauvre de la grande famille des choux.",
    nomRecette: "Rémoulade de chou rave",
    recette: [
      "800g de chou rave",
      "4 gousses d'ail",
      "30ml de mayonnaise",
      "20ml de moutarde de Dijon",
      "65ml d'huile d'olive",
      "1 citron pressé en jus",
      "sel & poivre"
    ],
    etapesRecette: [
      "Préparer la vinaigrette directement dans le bol de service: presser l'ail, ajouter la mayonnaise, la moutarde, l'huile, le jus de citron, ainsi que le sel et poivre au goût. Bien mélanger.",
      "Peler le chou-rave, puis le trancher finement avec une mandoline, ou le râper à l'aide d'une râpe à larges entailles et ajouter dans la vinaigrette. Bien mélanger.",
      "Vérifier l'assaisonnement et servir.",
    ]
  },
  {
    name: "Concombre",
    image: `${process.env.PUBLIC_URL}/images/legumes/concombre.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-concombre.jpg`,
    saison: "mars, avril, mai, juin, juillet, aout, septembre, octobre",
    aPropos: "Accessible à tous les porte-monnaie, le concombre est par ailleurs facile à préparer et peu calorique.",
    recolte: "La récolte estivale, plantée de février à mai, est en grande partie cultivée dans la terre. Le reste du temps, le légume est planté hors sol.",
    bienfaits: "On attribue au jus de concombre absorbé à jeun des propriétés dépuratives et sa richesse en eau (96%) lui confère des vertus diurétiques et drainantes. Ce légume est composé d'eau, de glucides, de fibres, de manganèse et de potassium.",
    bienChoisir: "Le concombre doit avoir les deux extrémités bien dures, la peau lisse, verte et ferme. Plus il est petit, moins il contient de graines et plus il est concentré en saveurs.",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Né selon toute vraisemblance dans le nord de l’Inde, le concombre s’est très tôt propagé vers la Chine et le Moyen-Orient. Il est ensuite cultivé sur les bords du Nil par les Égyptiens, qui en consomment beaucoup et le remettent en offrandes à leurs dieux.",
    nomRecette: "Concombre à la crème fraîche & ciboulette",
    recette: [
      "2 concombres",
      "200 ml de crème fraîche épaisse",
      "1 botte de ciboulette fraîche",
      "le jus d'un citron",
      "sel & poivre"
    ],
    etapesRecette: [
      "Nettoyez et coupez les concombres en fines rondelles, sans les éplucher. Placez-les dans un saladier. Réservez au frais.",
      "Mélangez la crème fraîche épaisse et le jus de citron dans un bol. Nettoyez et essuyez la ciboulette. Ciselez-la finement et ajoutez-la à la crème. Salez et poivrez à votre goût. Réservez au frais.",
      "Au moment de la dégustation, disposez les rondelles de concombre dans les assiettes et nappez-les de sauce à la crème. Décorez les assiettes avec des brins entiers de ciboulette fraîche et des rondelles de citron frais.",
      "Dégustez aussitôt avec des toasts de pain grillé.",
    ]
  },
  {
    name: "Courge",
    image: `${process.env.PUBLIC_URL}/images/legumes/courge.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-courge.jpg`,
    saison: "octobre, novembre, décembre, janvier",
    aPropos: "Emblématiques de l’automne et de l’hiver, les courges et potirons sont originaires d’Amérique du Sud.",
    recolte: "Les courges sont récoltés à la main entre 50 et 90 jours après la plantation. Très gourmands en eau, elles ont besoin d’être arrosés régulièrement.",
    bienfaits: "Courges et potirons affichent une large variété de couleurs, de formes et de tailles. Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène et de vitamine B5 et de potassium.",
    bienChoisir: "Privilégiez les courges qui possèdent encore leur pédoncule. L'écorce ne doit pas être fissurée mais doit être ferme au toucher.",
    conservation: "3 mois dans un endroit sec et frais.",
    nutriscore: "A",
    funFact: "La calebasse, une variété de courge, était bien connue des Romains. Pline l’Ancien cite son usage comme récipient, une fois évidée, et comme bouée pour aider les enfants à apprendre à nager !",
    nomRecette: "Soupe à la courge",
    recette: [
      "1 kg de courge musquée",
      "100 g de pomme de terre",
      "2 oignons",
      "1 carotte",
      "1 tranche de céleri",
      "1 petit choux rave",
      "1 c. à soupe de curry",
      "3 c. à soupe d'huile d'olive",
      "1/2 L de bouillon de légumes",
      "lait de coco pour la décoration",
      "quelques graines pour la décoration",
      "sel & poivre"
    ],
    etapesRecette: [
      "Épluchez tous les légumes et découpez-les en dés grossiers.",
      "Dans une casserole, faites suer les oignons avec le curry. Ajoutez tous les légumes et étuvez quelques minutes.",
      "Ajoutez le bouillon, portez à ébullition puis laissez mijoter environ 30min à couvert. Rectifiez l’assaisonnement si nécessaire.",
      "Passez le tout au blender jusqu’à obtenir une soupe crémeuse et lisse.",
      "Servez dans des assiettes creuses, décorez d’un tourbillon de lait de coco et de quelques graines pour ajouter un peu de croquant à la soupe"
    ]
  },
  {
    name: "Courgette",
    image: `${process.env.PUBLIC_URL}/images/legumes/courgette.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-courgette.jpg`,
    saison: "mai, juin, juillet, aout, octobre",
    aPropos: "Ce légume du soleil qui fleure bon la Méditerranée est présent dans les assiettes tout l’été. Facile à préparer, la courgette se consomme sous toutes ses formes.",
    recolte: "La récolte s’opère dès le début de l’été, quand les légumes mesurent une vingtaine de centimètres.",
    bienfaits: "Sa richesse en vitamines vous donne de l’énergie à la belle saison. Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine B9 et de potassium.",
    bienChoisir: "La courgette doit être lisse, dense et ferme au toucher. Sa peau ne doit pas présenter de taches brunes.",
    conservation: "5 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Comme son nom l’indique, la courgette appartient au groupe des courges. Elle se différencie par une cueillette précoce, avant totale maturité du légume. Ce sont les Italiens qui, au XVIIIe siècle, décidèrent de les déguster avant complète maturité. C’est ainsi que naquit la courgette !",
    nomRecette: "Spaghetti de courgettes",
    recette: [
      "400g de spaghetti de courgettes",
      "3 gousses d'ail",
      "4 c. à soupe de fines lamelles de parmesan",
      "1 c. à soupe d'huile d'olive",
      "4 feuilles de basilic",
      "sel & poivre"
    ],
    etapesRecette: [
      "Epluchez les gousses d’ail et coupez-les en petits dés . Lavez et séchez les feuilles de basilic et ciselez-les finement",
      "Versez l’huile d’olive dans une sauteuse anti-adhésive. Faites-y revenir les dés d’ail 2 minutes. Ajoutez les spaghettis de courgettes et faites sauter 3 minutes à feu vif avant d’ajouter le Parmesan et le poivre.",
      "Mélangez, laissez cuire 1 minute toujours à feu vif. Goûtez, salez si besoin et saupoudrez de basilic ciselé.",
    ]
  },
  {
    name: "Épinard",
    image: `${process.env.PUBLIC_URL}/images/legumes/epinard.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-epinard.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier, février, mars, avril, mai",
    aPropos: "Plante annuelle originaire du Caucase ou d’Asie Mineure, l’épinard est devenu populaire en France tardivement. Aliment bien-être, il vaut le coup d’être découvert ou redécouvert pour sa saveur.",
    recolte: "La récolte est faite manuellement et au jour le jour pour assurer une qualité optimale et continue sur les étals. Le producteur coupe d’abord les feuilles extérieures pour laisser les jeunes pousses se développer au cœur de la plante.",
    bienfaits: "L'épinard est reconnu pour ses qualités nutritionnelles et ses bienfaits énergétiques. Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène et de vitamine K1 et de manganèse.",
    bienChoisir: "Un épinard frais doit présenter un feuillage vert, lisse et charnu. La feuille doit être fine et humide sans pour autant être ramollie.",
    conservation: "2 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Au Moyen Âge, les Européens ne lui prêtent que peu d’attention et le considèrent même comme « l’herbe du carême », puisqu'en consommer est en soi une privation !",
    nomRecette: "Pakoras aux épinards",
    recette: [
      "400g de feuilles d'épinards fraîches",
      "1/2 tasse à thé de farine de lentilles",
      "10cl d'eau",
      "1/2 c. à café de bicarbonate de soude",
      "1/2 c. à café de piment en poudre",
      "1 pincée de coriandre en poudre",
      "sel & poivre"
    ],
    etapesRecette: [
      "Dans un grand saladier, mélangez la farine, le bicarbonate de soude et ajoutez l'eau progressivement jusqu'à obtenir une pâte homogène. Ajoutez le piment lle piment et la coriandre. Mélangez et salez à votre convenance",
      "Faites chauffer l'huile dans un wok. Trempez les feuilles d'épinards dans le mélange farine / épices, puis faites-les frire dans le wok. La pâte doit être dorée et bien croustillante.",
      "Epongez le surplus d'huile dans de l'essuie-tout. Servez tiède ou froid",
    ]
  },
  {
    name: "Fenouil",
    image: `${process.env.PUBLIC_URL}/images/legumes/epinard.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-fenouil.jpg`,
    saison: "mai, juin, juillet, aout, septembre, octobre, novembre, décembre",
    aPropos: "Légume très populaire en Italie, le fenouil se reconnaît à son parfum et à son goût anisé.",
    recolte: "La récolte débute en mai. On coupe ensuite les longues racines et on décoiffe les pommes en supprimant leur plumet. Elles sont lavées et mises en cagettes.",
    bienfaits: "Le fenouil est un aliment particulièrement intéressant sur le plan nutritionnel. Ce légume est composé d'eau, de glucides, de fibres, de de vitamine B9et de vitamine K1 et de potassium.",
    bienChoisir: "Ses feuilles doivent être bien vertes et en bon état. Son bulbe doit quand à lui être renflé, blanc et sans tâches.",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Originaire du bassin méditerranéen, le fenouil est aujourd’hui cultivé sur tous les continents mais on le trouve encore aujourd’hui à l’état sauvage sur les côtes méditerranéennes.",
    nomRecette: "Fenouil grillé",
    recette: [
      "2 bulbes de fenouil",
      "1 gousse d'ail",
      "1 citron",
      "huile d'olive",
      "sel & poivre"
    ],
    etapesRecette: [
      "Lavez les bulbes de fenouil. Coupez les tiges et les pieds des bulbes puis ôtez leurs feuilles abîmées.",
      "Portez à ébullition de l'eau dans une grande casserole à feu vif.",
      "Dès ébullition, baissez le feu à feu moyen et plongez les bulbes de fenouil dans l’eau. Faites-les blanchir pendant 5 minutes.",
      "A la fin de la cuisson, égouttez les bulbes de fenouil dans une passoire puis coupez-les en tranches moyennes. Réservez.",
      "Pelez et hachez finement l’ail.",
      "Coupez en deux le citron et pressez-le afin de récupérer son jus.",
      "Mélangez l'huile d'olive, le jus de citron et l’ail haché dans un saladier.",
      "Ajoutez les tranches de fenouil et mélangez soigneusement.",
      "Couvrez le saladier de film alimentaire et placez-le au réfrigérateur. Laissez mariner au frais pendant 1 heure minimum.",
      "A la fin de la marinade, faites chauffer une poêle à sec sur feu doux. Egouttez les tranches de fenouil et faites-les griller pendant 10 minutes dans la poêle, en les retournant à mi-cuisson, jusqu’à ce qu’elles soient bien grillées.",
      "Salez et poivrez à votre goût en fin de cuisson"
    ]
  },
  {
    name: "Maïs",
    image: `${process.env.PUBLIC_URL}/images/legumes/ble.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-mais.jpg`,
    saison: "aout, septembre, octobre",
    aPropos: "Le maïs est une céréale originaire d’Amérique, qu’on utilise parfois comme un légume. Très polyvalent, le maïs se présente sous mille et une formes : il peut être dégusté à même l’épi, congelé, en conserve ou soufflé.",
    recolte: "La date de récolte est très dépendante des conditions climatiques et de l'ensoleillement, de la variété, de la localisation géographique, de la disponibilité du matériel. La récolte commence généralement au début du mois d'aout et s'achève à la fin du mois de septembre.",
    bienfaits: "La consommation de grains entiers serait reliée à un risque moindre de maladies cardiovasculaires et de diabète, de certains cancers et d’obésité. Ce « légume » est composé d'eau, de protéines, de glucides, de fibres, de phospore, de magnésium, de fer et de vitamine C.",
    bienChoisir: "Les épis doivent posséder des grains gonflés et lisses. Les soies (petits filaments) doivent être pâles et humides",
    conservation: "2 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Il existe plusieurs variétés de maïs présentant des couleurs différentes. La plus connue et consommée en France est le maïs jaune, mais il existe également du maïs de couleur violette, blanche, bleue et rouge",
    nomRecette: "Galette de maïs",
    recette: [
      "230g de maïs égouttés", 
      "90g de farine de riz complète", 
      "90g de lait ou boisson végétale", 
      "1 pincée de piment d'Espelette",
      "2 c. à café de paprika",
      "2 c. à café de persil",
      "2 c. à café de bicarbonate de soude",
      "sel & poivre"
    ],
    etapesRecette: [
      "Dans un saladier, mélangez la farine de riz, le bicarbonate de soude et le lait.",
      "Ajoutez les épices, le sel et le persil puis mélangez à nouveau.",
      "Ajoutez le maïs et remuez jusqu'à ce que tous les grains soient incorporés dans la préparation.",
      "Faites chauffer une poêle avec très peu d'huile.",
      "Versez une c. à soupe de préparation dans la poêle puis faitez cuire. Retournez et laissez cuire 1 min lorsque la galette se décolle.",
      "Renouvelez l'opération jusqu'à épuisement de la pâte. Servez chaud."
    ]
  },
  {
    name: "Navet",
    image: `${process.env.PUBLIC_URL}/images/legumes/navet.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-navet.jpg`,
    saison: "octobre, novembre, décembre, janvier, février, mars, avril, mai",
    aPropos: "Originaire d’Europe de l’Est, cette racine gorgée d’eau n’est pas aussi fade que sa réputation le laisse entendre. Au contraire, son goût caractéristique donne une touche indispensable à de nombreuses spécialités régionales comme à des plats très raffinés.",
    recolte: "Le navet est une racine peut exigeante. La racine se récolte environ deux mois après le semis.",
    bienfaits: "Ses qualités nutritionnelles en font un compagnon de votre bien-être. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "Le navet ne doit pas présenter de taches brunes, flétrissures. Au nez, il doit dégager une odeur légère !",
    conservation: "4 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Longtemps légume de base d’une alimentation populaire, il était surnommé « légume de disette » ou « légume des pauvres ».",
    nomRecette: "Navets marinés",
    recette: [
      "500g de navet",
      "180ml de vinaigre",
      "250ml d'eau",
      "betteraves crues",
      "sel & poivre"
    ],
    etapesRecette: [
      "Déposez les cubes de navet dans un bol non métallique. Salez-les généreusement. Laissez dégorger 2 heures.",
      "Egouttez et rincez les navets. Repartissez en deux bocaux de 2 tasses (500 ml). Mélangez le vinaigre, l'eau, le sel et les tranches de betteraves. Versez dans les bocaux. Ajoutez de l'eau jusqu'a 1 cm du bord.",
      "Couvrez et laissez macérer 4 jours. Agitez les bocaux tous les jours pour permettre aux tranches de betteraves de bien colorer les navets.",
    ]
  },
  {
    name: "Oignon",
    image: `${process.env.PUBLIC_URL}/images/legumes/oignon.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-oignon.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier, février, mars, avril",
    aPropos: "S’invitant dans un nombre impressionnant de préparations, il se prête avec autant de réussite aux plats les plus rustiques qu’aux mets les plus sophistiqués.",
    recolte: "Les oignons primeurs sont principalement composés d’oignons blancs, présentés en bottes avec leurs feuilles vertes. Ils sont cueillis avant que les feuilles ne fanent et que le bulbe ne grossisse.",
    bienfaits: "Ses qualités nutritionnelles vous invitent à conjuguer bien-être et plaisir de la table. Ce légume est composé d'eau, de glucides, de fibres, de cuivre, de mangagnèse et de potassium.",
    bienChoisir: "L'oignon doit être ferme et ses couches de peau brillantes, sans taches. Si des moucherons se promènent autour des oignons, c'est un signe de putréfaction !",
    conservation: "4 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Cultivé depuis plus de 5000 ans, il est le symbole de l’intelligence dans la Chine ancienne !",
    nomRecette: "Confit d'oignon",
    recette: [
      "500g d'oignons rouges",
      "3 c. à soupe de vinaigre balsamique",
      "4 c. à soupe d'huile d'olive",
      "1 c. à café de sucre en poudre",
    ],
    etapesRecette: [
      "Épluchez les oignons et émincez-les finement. Verser l'huile d'olive dans une poêle et faites chauffer à feu vif.",
      "Ajoutez les oignons émincés et faites revenir jusqu'à ce qu'ils deviennent translucides.",
      "Déglacez au vinaigre balsamique. Ajoutez le sucre en poudre et poursuivez la cuisson pendant environ 15 min à feu doux, en remuant régulièrement.",
      "Lorsque les oignons sont bien confits, retirez la poêle du feu.",
      "Versez le confit d'oignon dans un bocal ou dans un pot à confiture. Faites refroidir et conservez au réfrigérateur."
    ]
  },
  {
    name: "Panais",
    image: `${process.env.PUBLIC_URL}/images/legumes/panais.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-panais.jpeg`,
    saison: "octobre, novembre, décembre, janvier, février",
    aPropos: "Le panais est l’un des légumes anciens qui connait un spectaculaire renouveau. On peut le consommer cru comme cuit.",
    recolte: "Les arrachages se font généralement au fur et à mesure des besoins. En attendant, pour protéger les racines du froid, le producteur peut recouvrir le sol de paille, ramener la terre en forme de butte, ou retourner les rangs.",
    bienfaits: "Il renferme de très nombreux minéraux et vitamines. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine E et de potassium.",
    bienChoisir: "Un panais doit être ferme, brillant et sans taches !",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Au Moyen Âge, le panais est considéré comme un aliment de base par le peuple. Quant à la classe bourgeoise et à la noblesse, elles l'évitent, comme tous les légumes d'ailleurs, réputés mauvais pour la santé.",
    nomRecette: "Purée de panais à la muscade",
    recette: [
      "1kg de panais",
      "20cl de lait",
      "50g de beurre",
      "noix de muscade",
      "sel"
    ],
    etapesRecette: [
      "Pelez les panais, lavez-les et coupez-les en cubes. Faites-les cuire à la vapeur pendant 20 min.",
      "Versez le lait dans une casserole et laissez chauffer à feu doux.",
      "Une fois cuits, retirez les panais et broyez-les à l'aide d'un moulin à légumes.",
      "Mettez la purée dans un grand bol et versez le lait chaud par-dessus. Mélangez bien.",
      "Ajoutez 1 pincée de sel, 1 pincée de noix de muscade râpée et le beurre tout en continuant de battre.",
      "Servez en accompagnement de viandes ou poissons."
    ]
  },
  {
    name: "Patate douce",
    image: `${process.env.PUBLIC_URL}/images/legumes/patate-douce.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-patatedouce.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier, février, mars",
    aPropos: "Probablement originaire d’Amérique centrale, la patate douce est un légume chargé de mystères. Sa chair orangée met de la gaieté dans tous vos plats. Délicatement sucrée, elle se cuisine de l’entrée au dessert.",
    recolte: "La culture de la patate douce est relativement aisée. Le semis se fait à partir du mois d’avril et la récolte s’effectue 4 à 6 mois plus tard, quand les feuilles commencent à dessécher.",
    bienfaits: "Peu calorique, la patate douce contient une quantité élevée d’antioxydants, de vitamine A et de minéraux. Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène et de vitamine C et de manganèse.",
    bienChoisir: "La patate douce doit être ferme et lourde en main. Au nez, elle ne doit pas présenter d'odeur marquante !",
    conservation: "1 mois dans un endroit sec et frais.",
    nutriscore: "A",
    funFact: "Des études archéologiques ont révélé la présence de patate douce au Pérou, 8 000 ans avant notre ère, mais rien n’indique si elle était domestiquée ou non.",
    nomRecette: "Frites de patates douces",
    recette: [
      "1kg de patates douces",
      "20g d'huile d'olive",
      "10g de paprika",
      "romarin",
      "sel"
    ],
    etapesRecette: [
      "Préchauffez le four à 200°C. Lavez et coupez les patates douces en quartiers.",
      "Déposez les quartiers de patates douces dans un bol. Versez l’huile d’olive, parsemez de paprika et de sel puis mélangez.",
      "Répartissez vos potatoes de patates douces sur la plaque recouverte de papier sulfurisé. Puis, saupoudrez de romarin avant d'enfourner 25 minutes à 200°C.",
      "Une fois votre plaque sortie du four, débarrassez aussitôt et dégustez chaud.",
    ]
  },
  {
    name: "Petit Pois",
    image: `${process.env.PUBLIC_URL}/images/legumes/grain.png`,    
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-petitpois.jpg`,
    saison: "mai, juin, juillet",
    aPropos: "Cultivé depuis des millénaires, le petit pois a longtemps été consommé sec comme le pois chiche. Légume printanier par excellence, le petit pois frais sait se faire désirer.",
    recolte: "La récolte, laborieuse, est faite à la main. Le petit pois ne supporte pas bien le stockage, il est donc envoyé le plus rapidement possible sur les étals.",
    bienfaits: "Ses qualités nutritionnelles originales en font un aliment de plaisir et de bien-être. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de manganèse et de phosphore.",
    bienChoisir: "Le petit pois frais est vendu dans sa cosse, celle-ci doit être verte, bien renflée, ferme et transpirante d'humidité !",
    conservation: "2 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Le petit pois est l’un des plus vieux légumes cultivés en Europe et en Asie. En Iran, en Palestine, en Grèce ou encore en Suisse, le petit pois était déjà présent il y a 10 000 ans.",
    nomRecette: "Velouté de petit pois à la menthe",
    recette: [
      "500g de petit pois",
      "3c. à soupe de crème fraiche",
      "2 verres d'eau",
      "1 noix de beurre",
      "3 brins de menthe",
      "sel & poivre"
    ],
    etapesRecette: [
      "Faites revenir les petits pois, égouttés au préalable, avec la noix de beurre pendant 5 min.",
      "Versez l'eau, remuez et portez à peine à ébullition 3 min environ. Ajoutez la crème fraîche, le sel et le poivre.",
      "Portez à ébullition pendant 5 min.",
      "Mixez, ajoutez les brins de menthe et dégustez.",
    ]
  },
  {
    name: "Piment",
    image: `${process.env.PUBLIC_URL}/images/legumes/piment.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-piment.jpg`,
    saison: "juillet, aout, septembre, octobre",
    aPropos: "Appartenant à la même famille que le poivron doux, le piment est renommé pour son goût piquant très caractéristique.",
    recolte: "La récolte intervient environ 1 mois après le début de la formation des fruits. Ils sont prêts à être récoltés lorsqu'ils sont bien colorés. Pour les variétés vertes à maturité, leur peau devient bien brillante.",
    bienfaits: "Le piment est reconnu avant tout pour ses vertus antioxydantes. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B6, de vitamine C, de fer et de manganèse.",
    bienChoisir: "Choisir des piments bien colorés avec une pelure lustrée sans taches ou parties amollies !",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "B",
    funFact: "Un piment explosif en bouche ? Ne buvez surtout pas d'eau qui risquerait de disperser la sensation de brûlure mais privilégiez plutôt du lait, de l'huile d'olive ou encore du pain !",
    nomRecette: "Purée de piment",
    recette: [
      "10 piments rouges frais",
      "5 gousses d'ail",
      "2 c. à café de graines de carvi",
      "2 c. à soupe de graines de cumin",
      "2 c. à café de coriandre en grains",
      "huile d'olive",
      "sel"
    ],
    etapesRecette: [
      "Pelez les gousses d'ail, coupez-les en deux et dégermez-les.",
      "Manipulez les piments avec des gants en caoutchouc. Lavez-les et séchez-les, retirez les pédoncules. Fendez-les en deux dans la longueur, ôtez les graines puis mettez-les dans le bol d'un mixeur avec l'ail, les épices et le sel.",
      "Mixez le tout en versant 12 cl d'huile en filet par la cheminée du mixeur jusqu'à obtention d'une pâte homogéne.",
      "Conservez-la au réfrigérateur dans de petits pots à confiture en recouvrant la purée de piments d'une mince couche d'huile d'olive.",
    ]
  },
  {
    name: "Poireau",
    image: `${process.env.PUBLIC_URL}/images/legumes/poireau.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-poireau.jpg`,
    saison: "septembre, octobre, novembre, décembre, janvier, février, mars, avril",
    aPropos: "Légume résistant, il entre dans la composition de nombreux plats grâce à son parfum si particulier et à sa saveur aillée.",
    recolte: "La récolte est effectuée mécaniquement ou à la main avant d'être acheminé en atelier de conditionnement",
    bienfaits: "Il est l’allié de votre bien-être grâce à ses qualités nutritionnelles. Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine B9, de vitamine E et de cuivre.",
    bienChoisir: "Le feuillage doit être ferme, sans flétrissures et vert. La racine doit avoir une forme renflée.",
    conservation: "5 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Il fut introduit en Grande-Bretagne par les Romains. Lors d’une guerre, les soldats gallois en avaient planté dans leurs chapeaux comme signe de reconnaissance. Cela leur valut la victoire. C’est ainsi qu’il devint le symbole du pays de Galles.",
    nomRecette: "tarte aux poireaux",
    recette: [
      "1 rouleau de pâte brisée",
      "400 g de poireaux",
      "200 ml de crème fraîche",
      "3 œufs",
      "50 g de fromage râpé",
      "sel"
    ],
    etapesRecette: [
      "Coupez les extrémités des poireaux, et coupez-les en deux dans la longueur. Passez-les sous l'eau pour enlevez l'excédent de terre. Coupez-les en lamelles, puis faites les cuire dans de l'eau chaude et égouttez-les.",
      "Préchauffez le four à 180°C.",
      "Déroulez la pâte brisée. Abaissez-la si besoin avec un rouleau à pâtisserie. Puis foncez la pâte dans le moule à tarte. Piquez celle-ci avec les dents d'une fourchette et réservez.",
      "Mixez le tout en versant 12 cl d'huile en filet par la cheminée du mixeur jusqu'à obtention d'une pâte homogéne.",
      "Dans un récipient, versez 3 oeufs avec la crème fraîche et une pincée de sel. Fouettez le mélange puis ajoutez le fromage râpée. Mélangez et ajoutez les poireaux cuits et versez la préparation dans le fond de tarte",
      "Enfournez pendant 35 à 45 min. la cuisson terminée, servez une part de quiche au poireaux végétarienne avec une petite salade et dégustez.",
    ]
  },
  {
    name: "Pois cassés",
    image: `${process.env.PUBLIC_URL}/images/legumes/pois.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-poiscasses.png`,
    saison: "février, mars, avril, mai, juin, juillet",
    aPropos: "Variété de petits pois dont on consomme la gousse, le pois cassé occupe une place de choix dans l’alimentation depuis des siècles.",
    recolte: "Les pois cassés sont en réalité des petits pois récoltés très jeunes, alors que le grain est à peine formé. Ils sont récoltés en février et en mars.",
    bienfaits: "Il est l’allié de votre bien-être grâce à ses qualités nutritionnelles atypiques. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de vitamine K1.",
    bienChoisir: "Le pois présente une cosse verte tendre et translucide. La gousse doit être ferme entre les doigts.",
    conservation: "1 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Le pois est cultivé depuis l’aube de l’Humanité. Cependant, sa consommation à l’état de gousse fraîche est relativement récente à l’échelle de l’histoire de l’agriculture et de la gastronomie.",
    nomRecette: "Soupe de pois",
    recette: [
      "200g de pois cassés",
      "1 poireau",
      "1 bouillon de volaille",
      "1 noix de beurre",
      "crème fraîche allégée",
      "herbes aromatiques",
      "sel & poivre"
    ],
    etapesRecette: [
      "La veille, faites tremper les pois cassés dans 1 l d’eau froide.",
      "Le lendemain, préparez les légumes : épluchez les carottes et coupez-les en morceaux. Nettoyez le poireau et coupez-le en julienne. Faites fondre la margarine dans une marmite et versez-y les légumes. Faites étuver le tout.",
      "Puis une fois le potage cuit, retirez le sachet aromatique et passez-le au mixeur. Assaisonnez.",
      "Versez dans des assiettes individuelles et mettez quelques gouttes de créme fraîche allégée pour la décoration.",
    ]
  },
  {
    name: "Poivron",
    image: `${process.env.PUBLIC_URL}/images/legumes/poivron.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-poivron.jpg`,
    saison: "juin, juillet, aout, septembre",
    aPropos: "Symbole d’été, de vacances et de grillades, le poivron arbore ses couleurs joyeuses sur les marchés.",
    recolte: "Le poivron vert est un poivron rouge ou jaune cueilli avant qu’il ne change de couleur. Il est tout aussi comestible, son goût est simplement plus amer.",
    bienfaits: "Peu calorique, il est également apprécié pour ses vitamines. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de vitamine E.",
    bienChoisir: "Le poivron doit être ferme en main, sans flétrissures et sa peau doit être bien brillante.",
    conservation: "7 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Certainement originaire d’Amérique du Sud, le poivron a vraisemblablement été cultivé pour la première fois au Mexique. Des graines vieilles de 5 000 ans y ont été retrouvées lors de fouilles archéologiques.",
    nomRecette: "Bruschettas aux poivrons rôtis & fromage frais",
    recette: [
      "200g de poivrons rouges grillés",
      "150g de fromage frais ail et fines herbes",
      "1 gousse d'ail",
      "1/2 baguette",
      "basilic",
      "1 c. à café de vinaigre balsamique",
      "huile d'olive",
      "sel & poivre"
    ],
    etapesRecette: [
      "Préchauffez le four en position grill.",
      "Détaillez la baguette en tranches d’1 à 2 cm d’épaisseur et badigeonnez un côté d’huile d’olive. Déposez-les sur une plaque de four et faites-les dorer 5 min.",
      "Sortez-les du four et nappez-les de fromage frais.",
      "Pelez et pressez l’ail. Coupez les poivrons en lanières.",
      "Dans un saladier, mélangez les poivrons avec l’ail, 1 c. à soupe d’huile, le vinaigre, le basilic lavé et ciselé, du sel et du poivre.",
      "Déposez une cuillère de mélange sur chaque tranche de pain au fromage, puis servez."
    ]
  },
  {
    name: "Pomme de terre",
    image: `${process.env.PUBLIC_URL}/images/legumes/pdt.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-patate.jpg`,
    saison: "mai, juin, juillet, aout, septembre, octobre, novembre, décembre, janvier, février, mars",
    aPropos: "Consommée depuis le XIXe siècle, la pomme de terre est rapidement devenue le légume préféré des Français. Il existe pas moin de 320 variétés !",
    recolte: "La pomme de terre s’acclimate dans la plupart des sols. La récolte début au mois de septembre. Elle se conserve jusqu’au début du printemps suivant. Les plants fanés sont arrachés à l’automne pour faire place à de nouveaux plants.",
    bienfaits: " .Ce légume est composé d'eau, de glucides, de fibres, de vitamine B6, de vitamine B9 et de potassium.",
    bienChoisir: "La couleur est homogène et la pomme de terre ne doit porter aucune trace de germination. Aucun noircissement ou verdissement n’est visible.",
    conservation: "1 mois dans un endroit sec et frais.",
    nutriscore: "C",
    funFact: "Longtemps boudée par les consommateurs français, la pomme de terre est réservée en premier lieu à la nourriture des cochons et des animaux de la ferme. Ce n’est qu’au XVIIIe siècle que le pharmacien Parmentier arrive à séduire les palais français.",
    nomRecette: "Rosties de pomme de terre",
    recette: [
      "1kg de pomme de terre",
      "2 oignons",
      "3 oeufs",
      "3 c. à soupe de farine",
      "1 c. à soupe d'huile",
      "1/2 bouquet de persil",
      "sel & poivre"
    ],
    etapesRecette: [
      "Épluchez les pommes de terre et râpez-les dans un saladier. Épluchez les oignons et émincez-les. Placez-les dans un saladier avec les pommes de terres râpées. Ajoutez les œufs, la farine, le persil lavé et ciselé, le sel et le poivre. Mélangez bien le tout.",
      "Formez de petites galettes : à l'aide d'une cuillère à soupe déposez des petits tas de votre préparation et aplatissez-les pour former les galettes avant de les faire cuire.",
      "Faites chauffer de l'huile dans une grande poêle puis venez y déposez les galettes de pommes de terre aux oignons. Faites-les dorer à feu doux pendant environ 8 à 10 min sur chaque face.",
      "Retournez les galettes de temps en temps jusqu'à ce qu'elles soient bien colorées des deux côtés. Elles doivent être « bronzées » à l'extérieur mais bien moelleuses à l'intérieur. Recommencez jusqu'à épuisement de la préparation.",
      "Déposez les galettes de pommes de terres aux oignons sur une assiette recouverte d'un papier absorbant pour supprimer l’excédent d'huile et ajoutez une pincée de sel",
      "Servez les galettes de pommes de terre aux oignons encore chaudes"
    ]
  },
  {
    name: "Radis",
    image: `${process.env.PUBLIC_URL}/images/legumes/radis.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-radis.jpg`,
    saison: "mars, avril, mai, juin, juillet",
    aPropos: "Plante potagère annuelle, le radis est une racine comestible originaire d’Asie mineure. Longues, rondes, petites ou grosses, les différentes variétés proposent une subtile palette de saveurs.",
    recolte: "La durée du cycle pour récolter varie de 18 à 90 jours suivant les périodes dans l’année. Les consommateurs adeptes du jardin potager connaissent bien le « radis de 18 jours ». Une fois récoltés, les radis sont douchés, et les racines, brossées.",
    bienfaits: "Le radis est un aliment plaisir reconnu aussi pour ses qualités nutritionnelles . Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "Le feuillage doit être bien vert, la peau quand à elle, doit être brillante et sans taches. Au toucher, le radis doit être ferme.",
    conservation: "1 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Chez les Grecs, le radis était dédié à Apollon. Le dieu recevait parfois des radis en or comme offrandes.",
    nomRecette: "Salade printanière",
    recette: [
      "1 botte de radis",
      "100-120g de quinoa",
      "2 oignons doux ou cébettes",
      "4 asperges blanches",
      "1 betterave Chioggia",
      "salade jeunes pousses",
      "vinaigrette"
    ],
    etapesRecette: [
      "Faites cuire le quinoa dans de l’eau bouillante selon les instructions de votre paquet : on compte en moyenne 15 minutes.",
      "Lavez les radis, retirez les fânes, la queue,  puis coupez les radis en rondelle.",
      "Lavez la betterave, pelez là puis découpez la en fines tranches.",
      "Lavez les asperges pelez les une première fois à l’économe. Découpez ensuite des tagliatelles dans les asperges.",
      "Lavez les oignons, retirez la partie abimée, puis émincez finement.",
      "Rassemblez les légumes et le quinoa froid dans un saladier ou dans des assiettes de service. Ajouter la vinaigrette.",
      "À dégustez frais."
    ]
  },
  {
    name: "Radis noir",
    image: `${process.env.PUBLIC_URL}/images/legumes/radisnoir.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-radisnoir.jpeg`,
    saison: "décembre, janvier, février, mars",
    aPropos: "Plante potagère annuelle, le radis noir est une racine comestible originaire d’Asie mineure.",
    recolte: "La durée du cycle pour récolter varie de 18 à 90 jours suivant les périodes dans l’année. Les consommateurs adeptes du jardin potager connaissent bien le « radis de 18 jours ». Une fois récoltés, les radis sont douchés, et les racines, brossées.",
    bienfaits: "Le radis noir est un aliment plaisir reconnu aussi pour ses qualités nutritionnelles. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B9, de vitamine C et de potassium.",
    bienChoisir: "Le feuillage doit être bien vert, la peau quand à elle, doit être brillante et sans taches. Au toucher, le radis noir doit être ferme.",
    conservation: "1 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Le radis noir n’est arrivé en France qu’au XVIe siècle : il ne sera consommé que 200 ans plus tard !",
    nomRecette: "Salade composée & graines",
    recette: [
      "1 radis noir",
      "2 c. à soupe d'huile d'olive",
      "2 c. à soupe de jus d'orange",
      "1 c. à soupe de miel liquide",
      "1 c. à soupe de vinaigre balsamique blanc ou rouge",
      "1 c. à café de jus de citron",
      "zeste d'une orange ou d'un citron",
      "persil",
      "piment d'espelette ou poivre noire",
      "fleur de sel"
    ],
    etapesRecette: [
      "Dans un saladier, mélangez l'huile d'olive, le jus d'orange, le jus de citron, le miel, le vinaigre balsamique et le persil frais haché",
      "Lavez le radis noir, essuyez-le et coupez de fines lamelles à l'aide d'un mandoline.",
      "Déposez les lamelles de radis noir dans la marinade et laissez reposer pendant 1h.",
      "Dressez les lamelles de radis noir dans des assiettes, arrosez de la marinade. Parsemez de piment d'espelette ou de poivre noir du moulin et déposez quelques grains de fleur de sel.",
      "À dégustez frais."
    ]
  },
  {
    name: "Salade",
    image: `${process.env.PUBLIC_URL}/images/legumes/salade.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-salade.jpg`,
    saison: "mai, juin, juillet, aout, septembre, octobre",
    aPropos: "La salade est une plante potagère dont on consomme les feuilles le plus souvent crues. De la saveur douce de la laitue en passant par le poivré naturel de la roquette ou l’amertume délicate de l’endive, les salades nous en font voir de toutes les saveurs !",
    recolte: "Selon les variétés de salades, les modes de culture varient. La récolte s'effectue à la main, à partir de mai, jusqu'en septembre.",
    bienfaits: "La salade est le légume par excellence, elle est riche en vitamines. Ce légume est composé d'eau, de glucides, de fibres, de vitamine B, de vitamine C, de vitamine E et de vitamines K.",
    bienChoisir: "Une salade bien fraîche présente par ailleurs des feuilles lisses, ou arbore une « frisure » vigoureuse, brillantes, non flétries et exemptes de tâches.",
    conservation: "3 jours dans le bac à légumes.",
    nutriscore: "A",
    funFact: "Dans la Bible, Moïse recommande aux fidèles d’entourer l’agneau pascal de feuilles de laitue sauvage, manifestement fort amère à l’époque, afin de se souvenir de l’amertume de l’exil.",
    nomRecette: "Salade composée & graines",
    recette: [
      "1 salade",
      "200g de tomates cerises",
      "200g de pignons",
      "200g de graines de chia et/ou sésame",
      "200g de noix",
      "huile de noix",
      "sel & poivre"
    ],
    etapesRecette: [
      "Lavez et égoutez la salade puis détaillez-la en feuilles.",
      "Dans un saladier, ajouter les feuilles de salades, les pignons, les graines et les noix.",
      "Taillez les tomates cerises dans deux puis ajoutez-les à la préparation.",
      "Ajoutez l'huile de noix, le sel et le poivre puis mélangez.",
      "À dégustez frais."
    ]
  },
  {
    name: "Tomate",
    image: `${process.env.PUBLIC_URL}/images/legumes/tomate.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-tomates.jpg`,
    saison: "juin, juillet, aout, septembre, octobre",
    aPropos: "Très simple à consommer, elle se prête à une infinité de préparations. C'est le légume le plus consommé en France !",
    recolte: "Les tomates peuvent être cultivées toute l’année sous serres. Les serres garantissent un climat idéal à leur croissance. Le producteur cueille les tomates selon leur degré de maturité et leur couleur, appréciée grâce à une échelle colorimétrique spécifique.",
    bienfaits: "Très riche au niveau nutritionnel, elle a de véritables atouts bien-être. Ce légume est composé d'eau, de glucides, de fibres, de provitamine A Béta-carotène, de vitamine C et de potassium.",
    bienChoisir: "La tomate doit être ferme, dense mais souple dans les doigts. Sa robe doit être lisse, brillante et sans taches.",
    conservation: "4 jours à température ambiante.",
    nutriscore: "A",
    funFact: "Originaire d’Amérique du Sud, la tomate a été implantée en Europe au XVIe siècle. Considérée comme vénéneuse, elle a été utilisée comme plante d’ornement avant d’être consommée comme un légume.",
    nomRecette: "Gaspacho",
    recette: [
      "700g de tomates",
      "200g de concombre",
      "1 oignon",
      "1 gousse d'ail",
      "basilic",
      "persil",
      "2 c. à soupe d'huile d'olive'",
      "1 c. à café de vinaigre de xérès",
      "sel & poivre"
    ],
    etapesRecette: [
      "Lavez le persil et le basilic. Pelez et émincez l'oignon et l'ail. Pelez le concombre, épépinez-les et coupez-le en morceaux.",
      "Ensuite, pour enlever facilement la peau des tomates, plongez-les quelques secondes dans une casserole d’eau bouillante, puis dans un saladier d'eau froide. Retirez-leur la peau puis tranchez-les en deux, épépinez-les et émincez-les grossièrement.",
      "Mettez dans le bol du mixeur la chair de tomates, la chair de concombre, l'ail, l'oignon et les herbes, et mixez.",
      "Quand le mélange est homogène (à la fois velouté et épais), ajoutez l'huile, le vinaigre de xérès (ou de vin), du sel et du poivre, et mixez à nouveau juste pour assaisonner.",
      "Rectifiez l'assaisonnement au besoin, et réfrigérez au moins 30 min avant de servir votre soupe froide dans des bols ou des assiettes creuses, et de déguster avec des tartines de pain grillé (et éventuellement frotté à l'ail)"
    ]
  },
]