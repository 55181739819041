export const FruitCoqueData = [
  {
    name: "Amande",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/amandes.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-amandes.jpg`,
    saison: "aout, septembre, octobre",
    aPropos: "L’amande se grignote à tout moment de la journée et vous régale de ses bienfaits. Ce petit en-cas se glisse également dans nombre de recettes gourmandes, salées ou sucrées.",
    recolte: "la récolte s'effectue en deux temps. Les amandes fraîches sont cueillies en juillet, avant complète maturité du fruit. Fragiles, elles ne sont récoltées qu’en petites quantités et proposées sur les marchés pendant 1 mois ou 2. Les fruits restants sont récoltés à la mi-septembre.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. L'amande est composée d'eau, de glucides, de fibres, de vitamine E et de cuivre.",
    bienChoisir: "L’amande fraîche est vendue dans sa coque. Celle-ci doit être ferme et d’un vert tendre bien franc. Les amandes sèches, quand elles sont vendues en vrac, doivent être dures et exhaler un léger parfum.",
    conservation: "2-4 jours à température ambiante.",
    nutriscore: "B",
    funFact: "L'amandier, de même que son fruit, a de tout temps été associé à la fertilité. Ce sont les Romains qui auraient institué la coutume de lancer des amandes aux jeunes mariées, pour favoriser une abondante progéniture, coutume qui perdure dans diverses régions d’Europe.",
  },
  {
    name: "Cacahuete",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/cacahuete.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-cacahuete.jpg`,
    saison: "aout, septembre",
    aPropos: "La cacahuète, ou graine d’arachide est originaire du Mexique et est aujourd’hui cultivée dans les régions tropicales et subtropicales du monde. Décortiquée, elle est d’ailleurs l’emblème de certains plats réputés à travers le monde : le mafé africain, le Bobun, etc. La cacahuète est tantôt classée parmi les légumineuses, tantôt parmi les fruits secs. Tout ce que l’on peut retenir c’est qu’elle a un profil nutritionnel pour le moins atypique !",
    recolte: "Les arachides sont récoltées en septembre - octobre, lorsque les graines mûrissent. Les plantes sont alors arrachées du sol en faisant ressortir les gousses avec les graines qui se toruvent sous la plante.",
    bienfaits: "Sa richesse en lipides participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine A, de vitamine B, de vitamine E et de potassium.",
    bienChoisir: "Si vous préférez la cacahuète en coque, veillez à ce que celle-ci soit ferme et ridée. Pour la version décortiquée, mieux vaut préférer les cacahuètes non salées et non grillées.",
    conservation: "3-6 mois dans un endroit frais et sec.",
    nutriscore: "C",
    funFact: "Quand on se remémore les films d'actions un peu cheap des années 80 où les explosions étaient légion, on était loin de se douter que les batons de dynamite utilisés comprenaient de la cacahuète. La nitroglycérine s'obtient à partir de glycérol, un liquide visqueux et translucide provenant de l'huile de cacahuètes.",
  },
  {
    name: "Châtaigne",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/chataigne.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-chataigne.jpg`,
    saison: "octobre, novembre, décembre",
    aPropos: "Connu depuis des millénaires, le châtaignier est toujours considéré comme un arbre nourricier. Plusieurs centaines de variétés peuplent aujourd’hui les forêts françaises.",
    recolte: "La récolte des fruits, de la mi-septembre à la fin octobre, démarre dès leur chute au sol. Elle se fait le plus souvent à la main, mais tend depuis peu à se mécaniser.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de glucides, de fibres, de vitamine B9, de polyphénols et de potassium.",
    bienChoisir: "Veillez à ce que le fruit soit bien brillant, c’est la garantie de sa fraîcheur. Attention toute fois à le regarder sous tous les angles : un trou dans la coque est souvent signe de ver ou d’insecte.",
    conservation: "5 jours dans le réfrigérateur.",
    nutriscore: "B",
    funFact: "Le châtaignier est présent depuis des millénaires sur Terre et y prospère depuis l’ère tertiaire. Si l’espèce faillit disparaître pendant l’ère glaciaire, elle fut vite réimplantée par l’homme qui considérait le châtaignier comme un arbre nourricier, fournissant à la fois des fruits pour l’alimentation et du bois pour l’ameublement.",
  },
  {
    name: "Noisette",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/noisette.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-noisette.jpg`,
    saison: "septembre, octobre, novembre",
    aPropos: "Son goût est subtil et légèrement lacté, tandis que sa texture se révèle très raffinée. Rare sur les étals, la noisette fraîche est un fruit à ne pas manquer !",
    recolte: "Les producteurs de noisettes sont les nuciculteurs. Ils doivent tailler les noisetiers en hiver. Ces arbres poussent naturellement en touffe. Mais le producteur les taille sur un seul tronc afin qu’ils bénéficient d’un ensoleillement maximal. Après récolte, les noisettes sont vendues en coque ou sont au préalable décortiquées.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. La noisette est composée d'eau, de fibres, de vitamine B9, de cuivre et de manganèse.",
    bienChoisir: "Lorsqu’elle est fraîche, la noisette est vendue dans son enveloppe verte. Pour bien la choisir, secouez-la ! Vous n’entendez rien ? C’est bon signe ! Quand elle est sèche, la noisette doit posséder une jolie coque brillante de couleur marron.",
    conservation: "2-3 semaines à température ambiante.",
    nutriscore: "B",
    funFact: "Les noisetiers sont originaires de la zone tempérée de l’hémisphère nord, particulièrement des régions qui bénéficient d’un hiver relativement doux et d’un été frais. Ce climat est caractéristique des bords de mer. Durant la préhistoire, les noisetiers couvrent d’immenses taillis dans toute l’Asie mineure. Les Turcs sont d’ailleurs les premiers à cultiver la noisette.",
  },
  {
    name: "Noix",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/noix.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-noix.jpg`,
    saison: "septembre, novembre, décembre",
    aPropos: "L’origine de la noix est très ancienne. La France est aujourd’hui l’un des plus grands producteurs européens et se distingue par ses deux AOC qui récompensent deux régions productrices.",
    recolte: "Le noyer est un arbre à la croissance rapide, qui peut atteindre jusqu’à 25 m. Il faut attendre 5 ou 6 ans avant qu’il ne produise des noix. La récolte des noix fraîches débute à la mi-septembre. Elles sont vendues non séchées et resteront sur les étals moins de 2 mois.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de fibres, de vitamine B9, de cuivre et de manganèse.",
    bienChoisir: "Pour sélectionner des noix goûteuses aux cerneaux charnus, il vous suffit… de les approcher de votre oreille et de les secouer ! Éliminez toutes celles dans lesquelles vous entendez le fruit bouger dans sa coquille : cela signifie qu’elle est desséchée.",
    conservation: "2-3 jours à température ambiante.",
    nutriscore: "B",
    funFact: "Les origines de la noix sont assez obscures. Certains botanistes affirment qu’elle proviendrait de nos contrées, reliquat des productions végétales de l’ère tertiaire. En Ardèche, une découverte a validé cette hypothèse : une noix de plus de 8 millions d’années a été retrouvée lors de fouilles archéologiques. Elle était consommée par l’homme de Cro-Magnon dans le Périgord il y a 17 000 ans !",
  },
  {
    name: "Noix du brésil",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/noix-bresil.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-noixbresil.jpeg`,
    saison: "janvier, février, mars, avril, mai, juin, juillet, aout, septembre, octobre, novembre, décembre",
    aPropos: "La noix du Brésil est un oléagineux originaire d’Amérique du Sud qui se distingue par sa teneur record en certains minéraux et par son profil lipidique hors norme. Côté cuisine, son goût à mi-chemin entre l’amande et la noisette ainsi que sa texture ultra-croquante permettent la création de recettes à la fois nutritives, saines et gourmandes.",
    recolte: "Les noix du Brésil sont disponibles toute l'année. Toutefois, leur cueillette se fait entre décembre et mars. Elles sont donc à leur meilleur durant les mois d'hiver et de printemps.",
    bienfaits: "Sa richesse en lipides participe à votre tonus au quotidien. Ce fruit est composé d'eau, de lipides, de fibres, de vitamine E, de sélénium, de magnésium et de zinc.",
    bienChoisir: "Comme elle rancit vite, de par son contenu élevé en gras, préférez des noix vendues dans des pots en verre, sous vide ou en conserve pour conserver le maximum de fraîcheur. Les noix qui ont conservé leur peau brune vont rancir moins rapidement.",
    conservation: "2 mois dans un endroit frais et sec.",
    nutriscore: "C",
    funFact: "La noix du Brésil est consommée par les Amérindiens depuis le Paléolithique. Les premières traces écrites de cet oléagineux unique remontent à 1548. En effet, elles sont décrites à l’époque comme des « amandes triangulaires qui finissent en pointe et sont plus grandes et meilleures que celles d’Espagne ».",
  },
  {
    name: "Noix de cajou",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/noix-cajou.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-cajou.jpg`,
    saison: "février, mars, avril, mai, juin",
    aPropos: "La noix de cajou est toujours vendue décortiquée, à cause de l’huile corrosive contenue entre ses deux coquilles. Elle est habituellement rôtie dans l’huile, puis additionnée ou non de sel. Idéale pour l'apéritif !",
    recolte: "La récole s'étend sur 4 à 6 mois qui varient selon les régions climatiques. Il faut attendre que les fruits tombent au sol pour les ramasser tout simplement. Toute fois, il faut être précautionneux lorsque la noix est détachée du fruit, celui-ci contenant une résine dangeureuse pour la santé !",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé de glucides, de vitamine B1, de vitamine B6, de vitamine B9, de magnésium et de manganèse.",
    bienChoisir: "On trouve sur le marché des noix de cajou « crues », entières, en moitiés, en morceaux, ainsi que des noix de cajou rôties à sec ou dans l’huile, salées ou non. Les noix sont classées en fonction de leur taille, de leur coloration et de leur teneur en eau.",
    conservation: "4-5 jour au réfrigérateur.",
    nutriscore: "C",
    funFact: "La coque de la noix de cajou est composée de deux coquilles entre lesquelles se loge une résine très caustique, le baume de cajou. On fait donc généralement rôtir la noix pour éliminer la résine avant de l’ouvrir. Le baume de cajou est utilisé dans la fabrication d’encres indélébiles, de produits imperméabilisants, de peintures, d’adhésifs ...",
  },
  {
    name: "Noix de pécan",
    image: `${process.env.PUBLIC_URL}/images/fruits_coque/noix-pecan.png`,
    mainImage: `${process.env.PUBLIC_URL}/images/item_Bg/main-pecan.jpg`,
    saison: "novembre, décembre, janvier",
    aPropos: "Fruit d’un arbre poussant abondamment aux États-Unis, la pacane faisait partie de l’alimentation amérindienne traditionnelle. Elle est toujours prisée aujourd’hui, son contenu en protéines étant relativement peu élevé en comparaison avec d’autres noix comme l’amande.",
    recolte: "Généralement, la récolte intervient en fin d'automne, vers novembre : les noix sont gaulées. La récolte s'effectue à la main, en ramssant les noix qui tombent de l'arbre. Elles se conservent durant un an environ, comme les noix.",
    bienfaits: "Sa richesse en vitamines participe à votre tonus au quotidien. Ce fruit est composé d'eau, de fibres, de vitamine B1, de vitamine E, de magnésium et de zinc.",
    bienChoisir: "Dans sa coque, si la noix fait du bruit lorsqu'on la secoue, c'est qu'elle n'est pas de la plus grande fraîcheur. Bien qu'il soit toujours préférable d'acheter les fruits oléagineux dans leur coque, car ils rancissent moins vite que ceux qui sont écalés, on évitera ceux qui sont vendus en vrac et l’on choisira de préférence des contenants hermétiques sur lesquels est inscrite une date de péremption.",
    conservation: "3 mois dans un endroit sec et frais.",
    nutriscore: "C",
    funFact: "Parmi les arbres donnant un fruit comestible, le pacanier est la plus importante espèce d'origine américaine. Il y a au moins 8 000 ans, les Amérindiens qui vivaient dans ce qui constitue l'actuel Texas consommaient déjà sa noix. Son aire de distribution s'étend du Midwest américain jusqu'au Mexique, où il pousse encore à l'état sauvage.",
  },
]